import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Divider,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Import Arrow Icon
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/index";
import MenuIcon from "@mui/icons-material/Menu";
import AuthServices from "../../../api/AuthServices/auth.index";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import { ErrorHandler } from "../../../utils/ErrorHandler";

function Header({ status, toggleStatus }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const userData = localStorage.getItem("user");
  const parsedData = JSON.parse(userData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null); // State for User Menu Popover

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setUserMenuAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const userMenuOpen = Boolean(userMenuAnchorEl); // Check if User Menu is Open
  const id = open ? "simple-popover" : undefined;
  const userMenuId = userMenuOpen ? "user-menu-popover" : undefined;
  const fcmToken = localStorage.getItem("fcmToken");
  const userLogout = async () => {
    const obj = {
      user_id: user.user._id,
      fcmToken: fcmToken,
    };
    try {
      const data = await AuthServices.logout(obj);
      SuccessToaster(data.message);
      localStorage.removeItem("user");
      localStorage.removeItem("fcmToken");
      navigate("/login");
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error);
    }
  };

  return (
    <AppBar sx={{ bgcolor: "#f5f5f5", boxShadow: 0 }}>
      <Toolbar sx={{ bgcolor: "#f5f5f5", pr: "0 !important" }}>
        <Grid container justifyContent={"space-between"}>
          <Grid
            item
            xs={12}
            sx={{
              transition: "all .3s ease-in-out",
              ml: status === false ? "60px" : 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "white !important",
                gap: "10px",
                p: 1,
                ml: "0px",
              }}
            >
              <Box></Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { md: "15px", xs: "0px" },
                  width: { md: "48%", xs: "100% " },
                  justifyContent: { md: "space-around", xs: "space-between" },
                }}
              >
                {window?.location?.pathname != "/tickets/create" && (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        borderRadius: "20px",
                        padding: {
                          xs: "auto",
                          sm: "10px 20px",
                          md: "10px 40px",
                        },
                        ml: { xs: 0, md: 5 },
                        textTransform: "none",
                      }}
                      onClick={() => navigate("/tickets/create")}
                    >
                      New Case
                    </Button>
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: { md: "15px", xs: "0px" },
                    ml: { xs: 0, md: 14 },
                  }}
                >
                  <IconButton>
                    <MailIcon sx={{ color: "#757575" }} />
                  </IconButton>
                  <Divider orientation="vertical" flexItem />
                  <IconButton onClick={handleNotificationClick}>
                    <NotificationsIcon sx={{ color: "#757575" }} />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {/* Notification Popover Content */}
                    <Box
                      sx={{
                        p: 2,
                        width: 300,
                        height: "150px",
                        overflow: "auto",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ mb: 1, fontWeight: "bold" }}
                      >
                        Notification
                      </Typography>
                      <Divider />
                      <List>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemIcon>
                            <CircleIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Follow Update - SEC0001932"
                            secondary="A second you are currently following has been updated. Click to ..."
                            primaryTypographyProps={{
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                            secondaryTypographyProps={{
                              fontSize: "10px",
                            }}
                          />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ p: 0 }}>
                          <ListItemIcon>
                            <CircleIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Follow Update - SEC0001932"
                            secondary="A second you are currently following has been updated. Click to ..."
                            primaryTypographyProps={{
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                            secondaryTypographyProps={{
                              fontSize: "10px",
                            }}
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </Popover>
                  <Divider orientation="vertical" flexItem />
                  {/* Avatar and Arrow Icon */}
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={handleUserMenuClick}
                  >
                    <Avatar
                      src={parsedData?.avatar}
                      alt={parsedData?.name}
                      sx={{ cursor: "pointer" }}
                    />
                    <IconButton>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </Box>
                  {/* User Menu Popover */}
                  <Popover
                    id={userMenuId}
                    open={userMenuOpen}
                    anchorEl={userMenuAnchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        width: 300,
                        bgcolor: "background.paper",
                        borderRadius: 2,
                        boxShadow: 3,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* Avatar Image */}
                        <Avatar
                          alt={parsedData?.name}
                          src={parsedData?.image}
                          sx={{ width: 48, height: 48, mr: 2 }}
                        />
                        <Box>
                          {/* User Name */}
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {parsedData?.name || "Inverness McKenzie"}
                          </Typography>
                          {/* User Role */}
                          <Typography
                            sx={{ fontSize: "13px" }}
                            color="textSecondary"
                          >
                            Information Security Officer
                          </Typography>
                        </Box>
                      </Box>

                      <Divider sx={{ my: 1 }} />

                      {/* List of Menu Items */}
                      <List sx={{ p: 0 }}>
                        {/* Profile Option */}
                        <ListItem
                          button
                          sx={{ py: 0 }}
                          onClick={() => {
                            navigate("/profile");
                            handleClose();
                          }}
                        >
                          <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                            primary="Profile"
                            sx={{ fontWeight: "bold" }}
                          />
                        </ListItem>

                        {/* Preferences Option */}
                        <ListItem button sx={{ py: 0 }}>
                          <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                            primary="Preferences"
                          />
                        </ListItem>

                        <Divider sx={{ my: 1 }} />

                        {/* Log Out Option */}
                        <ListItem button sx={{ py: 0 }} onClick={userLogout}>
                          <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                            primary="Log Out"
                            sx={{ fontWeight: "bold" }}
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </Popover>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
