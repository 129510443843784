import { AuthRoutes } from "./auth.route";
import { post, get, put, patch, deleted } from "../index";


const AuthServices = {
  login: async (obj) => {
    let result =  await post(AuthRoutes.login ,obj );
    console.log(result)
    return result;
  },
  logout: async (obj) => {
    let result =  await post(AuthRoutes.logout ,obj );
    console.log(result)
    return result;
  },
  getQrCode: async () => {
    let result =  await get(AuthRoutes.getQrCode  );
    console.log(result)
    return result;
  },
  getTwoFactorStatus: async (deviceKey) => {
    let result =  await get(AuthRoutes.getTwoFactorStatus + `?deviceKey=${deviceKey}`);
    console.log(result)
    return result;
  },
  verifyCode: async (obj) => {
    let result =  await patch(AuthRoutes.verifyCode ,obj );
    console.log(result)
    return result;
  },
  generatePasskeyChallenge: async (obj) => {
    let result =  await post(AuthRoutes.generatePasskeyChallenge ,obj );
    console.log(result)
    return result;
  },
  verifyPasskeyRegistration: async (obj) => {
    let result =  await post(AuthRoutes.verifyPasskeyRegistration ,obj );
    console.log(result)
    return result;
  },
  loginPassKeyChallenge: async (obj) => {
    let result =  await post(AuthRoutes.loginPassKeyChallenge ,obj );
    console.log(result)
    return result;
  },
  loginByPassKey: async (obj) => {
    let result =  await post(AuthRoutes.loginByPassKey ,obj );
    console.log(result)
    return result;
  },
  checkPassKeys: async (obj) => {
    let result = await post(AuthRoutes.checkPassKeys,obj );
    console.log(result)
    return result;
  },
  getPassKeys: async (obj) => {
    let result =  await get(AuthRoutes.getPassKeys );
    console.log(result)
    return result;
  },
  updatePassKeys: async (obj) => {
    let result =  await patch(AuthRoutes.updatePassKeys ,obj );
    console.log(result)
    return result;
  },
  changePassword: async (obj) => {
    let result =  await patch(AuthRoutes.changePassword ,obj );
    console.log(result)
    return result;
  },
  deletePassKeys: async (id) => {
    let result =  await deleted(AuthRoutes.deletePassKeys + `?id=${id}` );
    console.log(result)
    return result;
  },
  getRegisteredDevice: async (obj) => {
    let result =  await get(AuthRoutes.getRegisteredDevice );
    console.log(result)
    return result;
  },
  deleteRegisteredDevice: async (id) => {
    let result =  await deleted(AuthRoutes.deleteRegisteredDevice + `?id=${id}` );
    console.log(result)
    return result;
  },
};

export default AuthServices;
