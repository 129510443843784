import { ProfileRoutes } from "./route";
import { post, get, put,deleted,patch } from "../index";

const ProfileServices = {
 
  getProfile: async (id ) => {
    let result = get(ProfileRoutes.getProfile + `?user_id=${id}`);
    return result;
  },
  updateProfile: async (obj ) => {
    let result = put(ProfileRoutes.updateProfile,obj);
    return result;
  },

};

export default ProfileServices;
