import React, { Fragment, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import SideNav from "./sidenav";
import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import Colors from "../../assets/styles";

function DashboardLayout() {
  const [status, setStatus] = useState(false);

  const toggleStatus = () => {
    setStatus(!status);
  };

  return (
    <Fragment>
      <Box sx={{ position: "relative" }}>
        <Header status={status} toggleStatus={toggleStatus} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            // height: "auto",
            backgroundColor: Colors.dashboardBgColor,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              backgroundColor: Colors.dashboardBgColor,
            }}
          >
            <Box sx={{ width: status ? "0px" : "80px" }}>
              <SideNav status={status} toggleStatus={toggleStatus} />
            </Box>
            <Box
              sx={{
                height: "100%",
                flexGrow: 1,
                ml: 2,

                mr: {
                  xs:
                    window.location.pathname === "/tickets/create" ||
                    window.location.pathname === "/report"
                      ? 2
                      : 0,
                  md:
                    window.location.pathname === "/tickets/create" ||
                    window.location.pathname === "/report"
                      ? 0
                      : 2,
                },
                position: "relative",
                backgroundColor: Colors.dashboardBgColor,
                overflow: "auto",
              }}
            >
              {/* <Container maxWidth="xl" sx={{ mt: 10 }}> */}

              <Outlet />
              {/* </Container> */}
            </Box>
          </Box>
          <Footer status={status} toggleStatus={toggleStatus} />
        </Box>
      </Box>
    </Fragment>
  );
}

export default DashboardLayout;
