// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

// import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAAnwdbEBefYaN3J9qe80Vb2_moeTV8l0U",
    authDomain: "tms-f9205.firebaseapp.com",
    projectId: "tms-f9205",
    storageBucket: "tms-f9205.appspot.com",
    messagingSenderId: "900480548279",
    appId: "1:900480548279:web:c6fcb70a07734c46e3bc32",
    measurementId: "G-MN82G1R7ZY"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);