import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Images, Svgs } from "../../../assets/images";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Divider, FormControl, FormHelperText, IconButton, InputLabel } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import { Controller, useForm } from "react-hook-form";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import AuthServices from "../../../api/AuthServices/auth.index";
import { useAuth } from "../../../context";
import Loader from "../../../components/Loader";
import Colors from "../../../assets/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function SignUp() {
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setName } = useBreadCrumb();
  const navigate = useNavigate();
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { userLogin } = useAuth();
  let sleep = () => new Promise((r) => setTimeout(r, 2000));
  const login = async (formData) => {
    const obj = {
      email: formData.email,
      password: formData.password,
    };
    console.log(obj);
    setLoading(true);
    // try {
    //   const { data, responseCode, message } = await AuthServices.login(obj);
    //   await sleep();
    //   SuccessToaster(message);
    //   navigate("/dashboard");
    //   userLogin(data);
    //   setName("/dashboard");

    //   console.log(responseCode);
    // } catch (error) {
    //   ErrorHandler(error);
    //   ErrorToaster(error);
    //   console.log(error);
    // } finally {
    //   setLoading(false);
    // }
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          width: "100%",
          height: "100vh",
          display: { xs: "none", md: "flex" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            backgroundImage: `url(${Images.backgroundMain})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            display: "flex",
            paddingTop: "120px",
            // flexDirection: "column",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: "24px 0px",
              width: "80%",
              margin: " 0 20px",
              // padding:"10px 10px",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                background: Colors.white,
                borderRadius: "50%",
                width: "40px  ",
                height: "40px",
              }}
            ></Box>
            <Box
              sx={{
                color: Colors.white,
                textAlign: "justify",
                fontSize: "20px",
                borderRadius: "8px",
                mb: 1,
                width: "80%",
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "40px",
              }}
            >
              Complaint Managment
            </Box>
            <Box
              sx={{
                mt: 1,
                color: Colors.white,
                textAlign: "left",
                fontSize: "17px",
                borderRadius: "8px",
                width: "80%",
                mb: 1,
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              lobortis maximus nunc, ac rhoncus odio congue quis. Sed ac semper
              orci, eu porttitor lacus.
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px"
          }}
        >
          <Box sx={{ width: "70%", }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: "600", color: "#333" ,textAlign:"center"}}>
              Sign Up
            </Typography>
  
            <form onSubmit={handleSubmit(login)}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                <label htmlFor="phone" className="form-label" style={{color:Colors.grey}}>
             First Name
            </label>
                  <TextField
                    
                    fullWidth
                    {...register("firstName", { required: "First name is required" })}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                <label htmlFor="phone" className="form-label" style={{color:Colors.grey}}>
             Last Name
            </label>
                  <TextField
                   
                    fullWidth
                    {...register("lastName", { required: "Last name is required" })}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                <label htmlFor="phone" className="form-label" style={{color:Colors.grey}}>
             Email Address
            </label>
                  <TextField
                    
                    fullWidth
                    {...register("email", { required: "Email is required" })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                <label htmlFor="phone" className="form-label" style={{color:Colors.grey}}>
             Phone Number
            </label>
            <FormControl
              sx={{
                ".form-control": {
                  height: "56px !important",
                  background: "transparent !important",
                },
              }}
              error={!!errors.phone}
              fullWidth
            >
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Phone number is required",
                  validate: (value) => {
                    if (value.trim() === "") {
                      return "Phone Number is required";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <PhoneInput
                    country={"tr"} // Set default country to Pakistan
                    value={field.value}
                    onChange={field.onChange}
                    inputStyle={{ width: "100%" }}
                    countryCodeEditable={false}
                    enableSearch={true} // Enable country search
                    preferredCountries={["ky"]} // Add Cayman Islands to preferred countries
                    enableTerritories={true} // Enable territories
                  />
                )}
              />
              <FormHelperText>
                {errors.phone ? errors?.phone?.message : ""}
              </FormHelperText>
            </FormControl>
                </Grid>
                <Grid item xs={12} sx={{paddingTop:"0px !important"}}>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                 <label htmlFor="phone" className="form-label" style={{color:Colors.grey}}>
           Create Password
            </label>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    <Typography sx={{ color: Colors.grey }}>
                      {showPassword ? "Show" : "Hide"}
                    </Typography>
                  </Box>
                </Box>
                  <TextField
                  
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    {...register("password", { required: "Password is required" })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                  />
                </Grid>
                <Grid item xs={12} sx={{paddingTop:"0px !important"}}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label htmlFor="phone" className="form-label" style={{color:Colors.grey}}>
           Confirm Password
            </label>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton onClick={() => setShowPassword1(!showPassword1)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    <Typography sx={{ color: Colors.grey }}>
                      {showPassword ? "Show" : "Hide"}
                    </Typography>
                  </Box>
                </Box>
                  <TextField
                    
                    type={showPassword1 ? "text" : "password"}
                    fullWidth
                    {...register("confirmPassword", { required: "Please confirm your password" })}
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword?.message}
                    
                  />
                </Grid>
              </Grid>
  
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                    width: "50%",
                    margin: "16px auto",
                    backgroundColor: "#000",
                    color: "white",
                    display:"flex",
                    justifyContent:"center",
                    borderRadius: "30px",
                    height: "45px",
                    "&:hover": { backgroundColor: "#333" },
                  }}
              >
                {loading ? <Loader width={"20px"} height={"20px"} color={"white"} /> : "Sign Up"}
              </Button>
  
              <Divider sx={{ my: 2 }}>OR</Divider>
  
              <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                <img src={Images.insta} alt="Instagram" />
                <img src={Images.google} alt="Google" />
                <img src={Images.apple} alt="Apple" />
                <img src={Images.fb} alt="Facebook" />
              </Box>
  
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <Typography variant="body2">Already have an account?</Typography>
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline", cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate("/login")}
                >
                  Sign In
                </Typography>
              </Box>
            </form>
          </Box>
        </Grid>
    
    </Grid>
  );
}

export default SignUp;
