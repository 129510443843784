import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Container,
  Paper,
  PaginationItem,
  Pagination,
  FormControl,
  Select,
  MenuItem,
  TableSortLabel,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Colors from "../../../../assets/styles";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useNavigate } from "react-router-dom";
import CaseServices from "../../../../api/CaseServices/case.index";
import { ErrorHandler } from "../../../../utils/ErrorHandler";
import moment from "moment";
import { ErrorToaster, SuccessToaster } from "../../../../components/Toaster";
import SimpleDialog from "../../../../components/Dialog";
import { Images } from "../../../../assets/images";

export default function SupportTickets() {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [tickets, setTickets] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("submittedDate");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedId ,setSelectedId] = useState("")

  const tableHead = [
    { id: "_id", label: "ID" },
    { id: "payer", label: "Payer" },
    { id: "createdBy", label: "Created by" },
    { id: "insurance", label: "Insurance#" },
    { id: "caseReferenceNumber", label: "Case Ref#" },
    { id: "claimantName", label: "Claimant Name" },
    { id: "submittedDate", label: "Date Submitted" },
    { id: "reportDueDate", label: "Due Date" },
    { id: "assingedTo", label: "Assigned To" },
    { id: "clientStatus", label: "Status" },
    // { id: "Type", label: "Type" },
    { id: "action", label: "Action" },
  ];

  const getTickets = async (pageParam = 1, limitParam = 10) => {
    setLoading(true);
    try {
      const { data } = await CaseServices.getCases(pageParam, limitParam,status="assigned");
      setTickets(data.cases);
      setCount(data.totalCases);
      setLoading(false);
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
      setLoading(false);
    }
  };
  const deleteTicket = async () => {
    try {
      const { data } = await CaseServices.deleteCase(selectedId);
      SuccessToaster(data?.message);
      setDeleteDialog(false)
      getTickets(page, rowsPerPage,'assigned')
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    getTickets(page, rowsPerPage,"assigned");
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getTickets(newPage, rowsPerPage ,"assigned");
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedTickets = React.useMemo(() => {
    return [...tickets].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [tickets, order, orderBy]);

  return (
    <Container maxWidth="xl" sx={{ mt: 10 }}>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Typography
          variant="h6"
          sx={{
            color: Colors.primary,
            fontWeight: "600",
            fontFamily: "Poppins",
          }}
        >
          All Support Tickets (Assigned)
        </Typography>
        <Typography
          variant="h5"
          sx={{ color: "#A9B9C6", fontSize: "15px", mb: 2 }}
        >
          List of tickets opened by Customer
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: Colors.primary, fontSize: "16px" }}
          >
            Latest Tickets (Showing {rowsPerPage * (page - 1) + 1} to{" "}
            {Math.min(rowsPerPage * page, tickets?.length)} of {count} Tickets)
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" sx={{ mr: 1, color: Colors.textGrey }}>
              Sort By:
            </Typography>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 160 }}>
              <Select
                value={orderBy}
                onChange={(e) => handleRequestSort(e.target.value)}
                sx={{
                  fieldset: {
                    borderRadius: "20px !important",
                  },
                }}
              >
                <MenuItem value="submittedDate">Date Submitted</MenuItem>
                <MenuItem value="reportDueDate">Due Date</MenuItem>
                <MenuItem value="createdBy">Created By</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Paper
          sx={{ width: "100%", overflow: "hidden", mt: 2, boxShadow: "none" }}
        >
          <TableContainer
            sx={{ width: "100%", overflow: "auto", height: "380px" }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background: Colors.dashboardBgColor,
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  {tableHead.map((col) => (
                    <TableCell
                      key={col.id}
                      sx={{ color: Colors.textGrey, whiteSpace: "nowrap" }}
                    >
                      <TableSortLabel
                        active={orderBy === col.id}
                        direction={orderBy === col.id ? order : "asc"}
                        onClick={() => handleRequestSort(col.id)}
                      >
                        <Typography
                          sx={{ fontSize: "13px", fontWeight: "bold" }}
                        >
                          {col.label}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTickets.map((ticket, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: `18px solid ${Colors.dashboardBgColor}`,
                    }}
                    onClick={() =>
                      navigate(`/tickets/details/${ticket?._id}`, {
                        state: ticket,
                      })
                    }
                  >
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      #{ticket.caseID}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {ticket?.initiating?.payer}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {ticket?.createdBy?.firstName}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {ticket.insurance}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {ticket.caseReferenceNumber}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {ticket.claimantName}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {ticket.submittedDate != null
                        ? moment.utc(ticket.submittedDate).format(
                            "DD-MM-YYYY hh:mm A"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {ticket.reportDueDate != null
                        ? moment.utc(ticket.reportDueDate).format(
                            "DD-MM-YYYY hh:mm A"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {ticket?.assignTo?.firstName + " " + ticket?.assignTo?.lastName}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        px: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.yellow,
                          fontWeight: "bold",
                          fontSize: "13px",
                          background: "#f4e7d3",
                          display: "inline-block",
                          paddingY: "5px",
                          paddingX: "7px",
                        }}
                      >
                        {ticket.state}
                      </Typography>
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {ticket.initiating?.type}
                    </TableCell> */}
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        py: 3,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", fontSize: "13px" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <IconButton
                          sx={{ p: 0.6 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/tickets/details/${ticket?._id}`, {
                              state: ticket,
                            });
                          }}
                        >
                          <RemoveRedEyeIcon sx={{ fontSize: "15px" }} />
                        </IconButton>
                        <IconButton
                          sx={{ p: 0.6 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/tickets/update/${ticket?._id}`, {
                              state: ticket,
                            });
                          }}
                        >
                          <ModeEditIcon sx={{ fontSize: "15px" }} />
                        </IconButton>
                        {/* <IconButton
                          sx={{ p: 0.6 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteDialog(true)
                            setSelectedId(ticket?._id)
                           
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: "15px" }} />
                        </IconButton>
                        <IconButton sx={{ p: 0.6 }}>
                          <MoreVertIcon sx={{ fontSize: "15px" }} />
                        </IconButton> */}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mt: 3,
              alignItems: "center",
            }}
          >
            <Box
              onClick={(e) => handleChangePage(e, 1)}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                fontWeight: "bold",
                color: Colors.black,
                padding: "0 10px",
              }}
            >
              First
            </Box>

            <Pagination
              count={Math.ceil(count / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="circular"
              size="large"
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  slots={{
                    previous: () => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "bold",
                          color: Colors.black,
                          border: "none",
                          background: "transparent",
                          gap: "10px",
                        }}
                      >
                        <KeyboardDoubleArrowLeftIcon
                          sx={{ fontSize: "18px", mr: 0.5 }}
                        />
                      </Box>
                    ),
                    next: () => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "bold",
                          color: Colors.black,
                          border: "none",
                          background: "transparent",
                          gap: "10px",
                        }}
                      >
                        <KeyboardDoubleArrowRightIcon
                          sx={{ fontSize: "18px", ml: 0.5 }}
                        />
                      </Box>
                    ),
                  }}
                />
              )}
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: Colors.textGrey,
                  borderRadius: "50%",
                },
                "li button": {
                  border: "none !important",
                },
                "& .Mui-selected": {
                  backgroundColor: Colors.textBlack,
                  color: "#fff",
                },
                "& .MuiPaginationItem-ellipsis": {
                  color: Colors.textGrey,
                },
                "& .MuiPaginationItem-icon": {
                  fontSize: "18px",
                  color: Colors.textGrey,
                },
                "& .MuiPaginationItem-text": {
                  fontSize: "12px",
                },
                "& .MuiPaginationItem-page": {
                  borderRadius: "50%",
                  margin: "0 4px",
                },
                "& .MuiPaginationItem-page.Mui-selected": {
                  backgroundColor: Colors.black,
                  color: "#fff",
                },
                "& .MuiPaginationItem-page:not(.Mui-selected)": {
                  color: Colors.textGrey,
                },
                "& .MuiPaginationItem-page.Mui-selected:hover": {
                  backgroundColor: Colors.black,
                },
              }}
            />

            <Box
              onClick={(e) =>
                handleChangePage(e, Math.ceil(count / rowsPerPage))
              }
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                fontWeight: "bold",
                color: Colors.black,
                padding: "0 10px",
              }}
            >
              Last
            </Box>
          </Box>
        </Paper>
      </Box>


      {deleteDialog && (
  <>
    <SimpleDialog
      open={deleteDialog}
      onClose={() => setDeleteDialog(false)}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={Images.question} alt="Confirmation" />
        </Box>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "17px",
          }}
        >
          Are You Sure You Want To Delete
        </p>
        <p
          style={{
            textAlign: "center",
            color: "#666666",
            fontSize: "14px",
            marginTop: "8px",
          }}
        >
          {/* Additional message or leave it empty */}
        </p>

        {/* Yes and No Buttons */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
          <Box
            sx={{
              color: Colors.white,
              background: Colors.black,
              width: "80px",
              py: 1.2,
              cursor: "pointer",
              borderRadius: "10px",
              textAlign: "center",
            }}
            onClick={() => {
              setDeleteDialog(false);
              deleteTicket();
            }}
          >
            Yes
          </Box>
          <Box
            sx={{
              color: Colors.white,
              background: Colors.red,
              width: "80px",
              py: 1.2,
              cursor: "pointer",
              borderRadius: "10px",
              textAlign: "center",
            }}
            onClick={() => setDeleteDialog(false)}
          >
            No
          </Box>
        </Box>
      </Box>
    </SimpleDialog>
  </>
)}

    </Container>
  );
}
