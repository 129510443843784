import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Images, Svgs } from "../../../assets/images";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import { useForm } from "react-hook-form";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import AuthServices from "../../../api/AuthServices/auth.index";
import { useAuth } from "../../../context";
import Loader from "../../../components/Loader";
import Colors from "../../../assets/styles";
import GoogleIcon from "@mui/icons-material/Google";
import SimpleDialog from "../../../components/Dialog";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import KeyIcon from "@mui/icons-material/Key";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

function App() {
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [token, setToken] = useState("");
  const [loginobj, setLoginObj] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [fingerprint, setFingerprint] = useState("");

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      console.log(fp);
      const result = await fp.get();
      console.log(result);
      setFingerprint(result.visitorId); // Unique fingerprint ID
    };
    loadFingerprint();
  }, []);

  const { setName } = useBreadCrumb();
  const navigate = useNavigate();
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();
  const { userLogin } = useAuth();
  let sleep = () => new Promise((r) => setTimeout(r, 2000));
  console.log(token);
  // const login = async (formData) => {
  //   const obj = {
  //     email: formData.email,
  //     password: formData.password,
  //     isSocialLogin: false,
  //     token: token,
  //   };

  //   console.log(obj);
  //   setLoading(true);
  //   try {
  //     const res = await AuthServices.login(obj);
  //     console.log(res, "test try");
  //     const { data, responseCode, message } = res;
  //     await sleep();

  //     SuccessToaster(message);
  //     navigate("/dashboard");
  //     userLogin(data);
  //     setName("/dashboard");
  //     reset({ token: "" });
  //   } catch (error) {
  //     if (error.status == 206 && error?.data?.data?.twoFactorStatus == true) {
  //       setShowDialog(true);
  //       reset({ token: "" });
  //     } else if (error?.response?.status == 401) {
  //       ErrorToaster(error?.response?.data?.message);
  //       reset({ token: "" });
  //       setShowDialog(true);
  //     } else {
  //       ErrorToaster(error?.response?.data?.message);
  //       reset({ token: "" });
  //     }
  //     console.log(error, "test");
  //   } finally {
  //     reset({ token: "" });
  //     setLoading(false);
  //   }
  // };

  // const checkToken = (formData) => {
  //   setToken(formData?.token);
  //   setShowDialog(false);
  // };

  const login = async (formData, token) => {
    console.log("formData ===>", formData);
    setLoginObj(formData);
    const obj = {
      email: formData?.email ? formData?.email : loginobj?.email,
      password: formData?.password ? formData?.password : loginobj?.password,
      isSocialLogin: false,
      token: formData?.token ? formData?.token : "",
      deviceKey:fingerprint
    };

    console.log(obj);
    setLoading(true);

    try {
      const res = await AuthServices.login(obj);
      console.log(res, "test try");
      const { data, responseCode, message } = res;
console.log(data)
      await sleep();

      setToken("");
      reset({ token: "" });

      SuccessToaster(message);
      navigate("/dashboard");
      userLogin(data);
      setName("/dashboard");
    } catch (error) {
      if (
        error?.status === 206 &&
        error?.data?.data?.twoFactorStatus === true
      ) {
        setShowDialog(true);
      } else if (error?.response?.status === 401) {
        ErrorToaster(error?.response?.data?.message);
        setToken("");
        reset({ token: "" });
      } else {
        ErrorToaster(error?.response?.data?.message);
        setToken("");
        reset({ token: "" });
      }

      setToken("");
      reset({ token: "" });

      console.log(error, "test");
    } finally {
      setToken("");
      reset({ token: "" });
      setLoading(false);
    }
  };

  const checkToken = (formData) => {
    setToken(formData?.token);
    setShowDialog(false);
    setLoginObj((prev) => ({ ...prev, token: formData.token }));
    login({ ...loginobj, token: formData.token });
  };
  function base64urlToUint8Array(base64url) {
    const base64 = base64url.replace(/-/g, "+").replace(/_/g, "/");
    const paddedBase64 = base64.padEnd(
      base64.length + ((4 - (base64.length % 4)) % 4),
      "="
    );
    const rawData = atob(paddedBase64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
  }

  async function loginWithPasskey() {
    const obj = {
      email: getValues("email"),
    };

    try {
      const response = await AuthServices.loginPassKeyChallenge(obj);
      const options = response?.data?.options;

      if (!options || !options.allowCredentials) {
        throw new Error("Invalid options received from server.");
      }

      const allowCredentials = options.allowCredentials.map((cred) => ({
        ...cred,
        id: base64urlToUint8Array(cred.id),
      }));

      const publicKeyOptions = {
        ...options,
        challenge: base64urlToUint8Array(options.challenge),
        allowCredentials: allowCredentials,
      };

      console.log("publicKeyOptions:", publicKeyOptions);

      // Use navigator.credentials.get to obtain credentials
      const credential = await navigator.credentials.get({
        publicKey: publicKeyOptions,
      });

      const obj2 = {
        email: getValues("email"),
        credential: {
          id: credential.id,
          type: credential.type,
          rawId: Array.from(new Uint8Array(credential.rawId)),
          response: {
            clientDataJSON: Array.from(
              new Uint8Array(credential.response.clientDataJSON)
            ),
            authenticatorData: Array.from(
              new Uint8Array(credential.response.authenticatorData)
            ),
            signature: Array.from(
              new Uint8Array(credential.response.signature)
            ),
            userHandle: credential.response.userHandle
              ? Array.from(new Uint8Array(credential.response.userHandle))
              : null,
          },
        },
      };

      // Send the credential to the backend for verification
      const authResponse = await AuthServices.loginByPassKey(obj2);
      console.log(authResponse?.data?.user);
      if (authResponse?.data?.user?.token) {
        SuccessToaster(authResponse?.message);
        navigate("/dashboard");
        userLogin(authResponse?.data);
        setName("/dashboard");
      } else {
        ErrorToaster(authResponse?.message);
      }
    } catch (error) {
      console.error("Authentication failed", error);
    }
  }

  const ChangeStep = async (formData) => {
    const obj = {
      email: formData?.email,
    };
    try {
      const response = await AuthServices.checkPassKeys(obj);
      // SuccessToaster(response?.message);
      if (
        response?.responseCode == "200" &&
        response?.data?.isPassKey == true
      ) {
        setActiveStep(1);
      } else {
        setActiveStep(2);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      ErrorToaster(error?.response?.data?.message);
    }
  };
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            width: "100%",
            height: "100vh",
            display: { xs: "none", md: "flex" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              backgroundImage: `url(${Images.backgroundMain})`,
              backgroundSize: "cover",
              backgroundPosition: "bottom",
              backgroundRepeat: "no-repeat",
              display: "flex",
              paddingTop: "120px",
              // flexDirection: "column",
              // alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                padding: "24px 0px",
                width: "80%",
                margin: " 0 20px",
                // padding:"10px 10px",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  background: Colors.white,
                  borderRadius: "50%",
                  width: "40px  ",
                  height: "40px",
                }}
              ></Box>
              <Box
                sx={{
                  color: Colors.white,
                  textAlign: "justify",
                  fontSize: "20px",
                  borderRadius: "8px",
                  mb: 1,
                  width: "80%",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "40px",
                }}
              >
                Complaint Managment
              </Box>
              <Box
                sx={{
                  mt: 1,
                  color: Colors.white,
                  textAlign: "left",
                  fontSize: "17px",
                  borderRadius: "8px",
                  width: "80%",
                  mb: 1,
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                lobortis maximus nunc, ac rhoncus odio congue quis. Sed ac
                semper orci, eu porttitor lacus.
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "120px",
            height: { xs: "100vh", md: "auto" },
            justifyContent: "center", // Center content vertically
          }}
        >
          <Grid container justifyContent={"center"}>
            <Grid item xs={9} md={8}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    marginBottom: "20px",
                    color: "#333",
                    fontWeight: "600",
                    fontSize: "25px",
                  }} // Adjusted Typography variant and margin
                >
                  Sign in
                </Typography>
                {activeStep == 0 && (
                  <>
                    <Box
                      component="form"
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        // maxWidth: "400px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      onSubmit={handleSubmit(ChangeStep)}
                    >
                      <InputLabel sx={{ alignSelf: "flex-start" }}>
                        User name or email address
                      </InputLabel>
                      <TextField
                        fullWidth
                        sx={{
                          mt: 0.5,
                          "& fieldset": {
                            borderRadius: "10px",
                            border: "1px solid grey !important",
                            "&.Mui-focused": {
                              border: "1px solid grey !important",
                            },
                            "&:active": {
                              border: "1px solid grey !important",
                            },
                          },
                        }}
                        type="email"
                        {...register("email", {
                          required: "Please enter your Email",
                        })}
                        error={Boolean(errors?.email)}
                        helperText={errors?.email?.message}
                      />

                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          width: "50%",
                          marginTop: "25px",
                          backgroundColor: "#000",
                          color: "white",
                          borderRadius: "30px",
                          height: "45px",
                          "&:hover": { backgroundColor: "#333" },
                        }}
                      >
                        {loading ? (
                          <Loader
                            width={"20px"}
                            height={"20px"}
                            color={"white"}
                          />
                        ) : (
                          "Continue"
                        )}
                      </Button>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 2,
                          gap: "6px",
                        }}
                      >
                        <Typography variant="body2" sx={{ color: "#333" }}>
                          Don't have an account?{" "}
                        </Typography>
                        <Box
                          // onClick={() => navigate("/sign-up")}
                          onClick={() => SuccessToaster("Coming Soon")}
                          sx={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                        >
                          Sign up
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Divider sx={{ flexGrow: 1 }} />
                        <Typography
                          variant="body2"
                          sx={{ margin: "0 10px", color: "#333" }}
                        >
                          OR
                        </Typography>
                        <Divider sx={{ flexGrow: 1 }} />
                      </Box>
                      <Button
                        variant="contained"
                        sx={{
                          width: "50%",
                          marginTop: "25px",
                          backgroundColor: "#000",
                          color: "white",
                          borderRadius: "30px",
                          height: "45px",
                          "&:hover": { backgroundColor: "#333" },
                        }}
                      >
                        {/* {loading ? (
                    <Loader width={"20px"} height={"20px"} color={"white"} />
                  ) : (
                    <> */}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <GoogleIcon />
                          </Box>
                          <Box>Sign in With Google</Box>
                        </Box>
                        {/* </>
                  )} */}
                      </Button>
                    </Box>
                  </>
                )}
                {activeStep == 1 && (
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      bgcolor="white"
                      padding={4}
                      borderRadius={4}
                      boxShadow={3}
                      width="100%"
                      maxWidth="400px" // Limit width to maintain a structured layout
                    >
                      {/* User Email */}
                      <Box display="flex" alignItems="center" mb={2}>
                        <Avatar
                          sx={{ marginRight: 1, bgcolor: "rgb(50 60 71)" }}
                        >
                          <AccountCircleIcon />
                        </Avatar>
                        <Typography variant="body1">
                          {getValues("email")}
                        </Typography>
                      </Box>

                      {/* Choose how you want to sign in */}
                      <Typography variant="body2" color="textSecondary" mb={2}>
                        Choose how you want to sign in:
                      </Typography>

                      {/* Sign-in Options as List */}
                      <List sx={{ width: "100%" }}>
                        <ListItem button onClick={loginWithPasskey}>
                          <ListItemIcon>
                            <KeyIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Use your passkey" />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            setActiveStep(2);
                          }}
                        >
                          <ListItemIcon>
                            <LockIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Enter your password" />
                        </ListItem>
                      </List>
                    </Box>
                  </>
                )}
                {activeStep == 2 && (
                  <>
                    <Box
                      component="form"
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        // maxWidth: "400px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      onSubmit={handleSubmit(login)}
                    >
                      <InputLabel sx={{ alignSelf: "flex-start" }}>
                        User name or email address
                      </InputLabel>
                      <TextField
                        fullWidth
                        sx={{
                          mt: 0.5,
                          "& fieldset": {
                            borderRadius: "10px",
                            border: "1px solid grey !important",
                            "&.Mui-focused": {
                              border: "1px solid grey !important",
                            },
                            "&:active": {
                              border: "1px solid grey !important",
                            },
                          },
                        }}
                        type="email"
                        {...register("email", {
                          required: "Please enter your Email",
                        })}
                        error={Boolean(errors?.email)}
                        helperText={errors?.email?.message}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <InputLabel sx={{ alignSelf: "flex-start", mt: 1.5 }}>
                          Your Password
                        </InputLabel>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                          <Typography sx={{ color: Colors.grey }}>
                            {showPassword ? "Show" : "Hide"}
                          </Typography>
                        </Box>
                      </Box>
                      <TextField
                        fullWidth
                        sx={{
                          mt: 0.5,
                          "& fieldset": {
                            borderRadius: "10px",
                            border: "1px solid grey !important",
                            "&.Mui-focused": {
                              border: "1px solid grey !important",
                            },
                            "&:active": {
                              border: "1px solid grey !important",
                            },
                          },
                        }}
                        type={showPassword ? "text" : "password"}
                        {...register("password", {
                          required: "Please enter your Password",
                          validate: (value) =>
                            value.trim() !== "" || "Please enter your Password",
                        })}
                        error={Boolean(errors?.password)}
                        helperText={errors?.password?.message}
                        // InputProps={{
                        //   endAdornment: (
                        //     <IconButton
                        //       onClick={() => setShowPassword(!showPassword)}
                        //     >
                        //       {showPassword ? <Visibility /> : <VisibilityOff />}
                        //     </IconButton>
                        //   ),
                        // }}
                      />

                      <Typography
                        variant="body2"
                        sx={{
                          alignSelf: "flex-end",
                          color: "grey",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Forget your password
                      </Typography>

                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          width: "50%",
                          marginTop: "25px",
                          backgroundColor: "#000",
                          color: "white",
                          borderRadius: "30px",
                          height: "45px",
                          "&:hover": { backgroundColor: "#333" },
                        }}
                      >
                        {loading ? (
                          <Loader
                            width={"20px"}
                            height={"20px"}
                            color={"white"}
                          />
                        ) : (
                          "Sign in"
                        )}
                      </Button>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 2,
                          gap: "6px",
                        }}
                      >
                        <Typography variant="body2" sx={{ color: "#333" }}>
                          Don't have an account?{" "}
                        </Typography>
                        <Box
                          // onClick={() => navigate("/sign-up")}
                          onClick={() => SuccessToaster("Coming Soon")}
                          sx={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                        >
                          Sign up
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Divider sx={{ flexGrow: 1 }} />
                        <Typography
                          variant="body2"
                          sx={{ margin: "0 10px", color: "#333" }}
                        >
                          OR
                        </Typography>
                        <Divider sx={{ flexGrow: 1 }} />
                      </Box>
                      <Button
                        variant="contained"
                        sx={{
                          width: "50%",
                          marginTop: "25px",
                          backgroundColor: "#000",
                          color: "white",
                          borderRadius: "30px",
                          height: "45px",
                          "&:hover": { backgroundColor: "#333" },
                        }}
                      >
                        {/* {loading ? (
                    <Loader width={"20px"} height={"20px"} color={"white"} />
                  ) : (
                    <> */}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <GoogleIcon />
                          </Box>
                          <Box>Sign in With Google</Box>
                        </Box>
                        {/* </>
                  )} */}
                      </Button>
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 2,
                    gap: "6px",
                  }}
                >
                  <Typography variant="body2" sx={{ color: "#333" }}>
                    Don't have an account?{" "}
                  </Typography>
                  <Box
                    // onClick={() => navigate("/sign-up")}
                    onClick={() => SuccessToaster("Coming Soon")}
                    sx={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                  >
                    Sign up
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showDialog && (
        <>
          <SimpleDialog
            open={showDialog}
            onClose={() => {
              setShowDialog(false);
              reset({ token: "" });
            }}
            loginObj={loginobj}
            title="Enter Code"
          >
            <Box
              component="form"
              onSubmit={handleSubmit(checkToken)}
              sx={{ mt: 2 }}
            >
              {/* Token Field */}
              <InputLabel>Token</InputLabel>
              <TextField
                type="text"
                placeholder="Enter Token"
                variant="outlined"
                fullWidth
                {...register("token", { required: "Token is required" })}
                error={!!errors.token}
                helperText={errors.token ? errors.token.message : ""}
                style={{ marginTop: "0px", width: "100%" }}
              />

              <Box
                sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}
              >
                <Button
                  sx={{ border: "1px solid black" }}
                  onClick={() => {
                    setShowDialog(false);
                    reset({ token: "" });
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </Box>
          </SimpleDialog>
        </>
      )}
    </>
  );
}

export default App;
