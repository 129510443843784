import {UserRoutes } from "./route";
import { post, get, put,patch } from "../index";



const SkillServices = {
  getRoles: async (skill) => {
    let result = get(UserRoutes.getRoles );
    return result;
  },
  getUsers: async (roleId) => {
    let result = get(UserRoutes.getUsers + `?role_id=${roleId}` );
    return result;
  },
 

};

export default SkillServices;
