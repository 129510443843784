import { Box } from '@mui/material'
import React from 'react'

export default function Chat() {
  return (
    <Box sx={{mt:10,height:'80vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',fontWeight:"bold"}}>
     Coming soon ..
    </Box>
  )
}
