import { CaseRoutes } from "./case.route";
import { post, get, put,deleted,patch } from "../index";

const CaseServices = {
  createCase: async (obj) => {
    let result = post(CaseRoutes.createCase, obj);
    return result;
  },
  getCases: async (page,limit,status) => {
    let result = get(CaseRoutes.getCases + `?page=${page}&limit=${limit}&status=${status}`);
    return result;
  },
  getCaseDetail: async (id) => {
    let result = get(CaseRoutes.getCaseDetail + `?id=${id}`);
    return result;
  },
  deleteCase: async (id ) => {
    let result = deleted(CaseRoutes.deleteCase + `?id=${id}`);
    return result;
  },
  updateCase: async (id ,obj ) => {
    let result = put(CaseRoutes.updateCase + `?id=${id}`,obj);
    return result;
  },
  assignCase: async (obj ) => {
    let result = patch(CaseRoutes.assignCase ,obj);
    return result;
  },
};

export default CaseServices;
