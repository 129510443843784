import React from "react";
import {
  AppBar,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
import SearchIcon from "@mui/icons-material/Search";
import Colors from "../../../assets/styles"; // Assuming Colors is defined in your assets
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import { useAuth } from "../../../context/index";

function Header({ status, toggleStatus }) {
  const { pageName } = useBreadCrumb();
  const { userLogout } = useAuth();

  const userData = localStorage.getItem("user");
  const parsedData = JSON.parse(userData);

  return (
    <Box sx={{ bgcolor: Colors.dashboardBgColor, boxShadow: 0 ,mb:1}}>
      <Box sx={{ bgcolor: Colors.dashboardBgColor, pr: "0 !important" }}>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            sx={{
              transition: "all .3s ease-in-out",
              ml: status === false ? "60px" : 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "white !important",
                gap: "10px",
                p: 1,
                ml: "24px",
                pt:2,
                flexDirection:{
                  xs:"column",
                  md:"row"
                }
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <Typography sx={{ color: Colors.textGrey, fontSize:{
                  md:"14px",
                  xs:"13px"
                } }}>
                  Careers
                </Typography>
                <Typography sx={{ color: Colors.textGrey, fontSize:{
                  md:"14px",
                  xs:"13px"
                } }}>
                  Support
                </Typography>
                <Typography sx={{ color: Colors.textGrey, fontSize:{
                  md:"14px",
                  xs:"13px"
                } }}>
                  Legal & Privacy Policy
                </Typography>
              </Box>
             
              <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
               
                <Typography sx={{ color: Colors.textGrey, fontSize:{
                  md:"14px",
                  xs:"13px"
                } }}>
                  copyrights © Ticket Managment
                </Typography>

              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Header;
