import { yellow } from "@mui/material/colors";

const Colors = {
  backgroundColor: "#F1FEFD",
  //dashboardBgColor: "#e0e8e9",
  dashboardBgColor: "#f7f8f9",
  primary: "#323C47",
  yellow: "#EDA845",
  seaGreen: "rgba(0, 182, 155, 1)",
  red:"rgba(253, 84, 84, 1)",

  textGrey:"#A9B9C6",
  secondary: "#0BD1D1",
  white: "#FFFFFF",
  black: "#000000",
  textColor1: "#B9E0F2",
  textColor2: "#7F92B0",
  grey: "#666666",
};

export default Colors;
