import Dashboard from "../../views/admin/dashboard";
import Login from "../../views/admin/Login/index";

import CreateTicket from "../../views/admin/Tickets/CreateTicket/index";
import AssignedTicket from "../../views/admin/Tickets/TicketList/assignedTickets";
import UnAssignedTicket from "../../views/admin/Tickets/TicketList/unassignedTickets";
import TicketDetails from "../../views/admin/Tickets/ticketDetails";
import UpdateTicket from "../../views/admin/Tickets/UpdateTickets";
import Report from "../../views/admin/Report";
import Profile from "../../views/admin/Profile";
import Users from "../../views/admin/Users/UserList";
import Chat from "../../views/admin/Chat";
import UserSetting from "../../views/admin/Users/UserSetting";

const AdminRoutes = [
  {
    path: "/login",
    component: <Login />,
  },

  {
    path: "/dashboard",
    component: <Dashboard />,
  },

  {
    path: "/tickets/assigned",
    component: <AssignedTicket />,
  },
  {
    path: "/tickets/unassigned",
    component: <UnAssignedTicket />,
  },
  {
    path: "/tickets/create",
    component: <CreateTicket />,
  },
  {
    path: "/tickets/update/:id",
    component: <UpdateTicket />,
  },
  {
    path: "/tickets/details/:id",
    component: <TicketDetails />,
  },
  {
    path: "/report",
    component: <Report />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/users",
    component: <Users />,
  },
  {
    path: "/users/settings",
    component: <UserSetting />,
  },
  {
    path: "/chat",
    component: <Chat />,
  },
];

export default AdminRoutes;
