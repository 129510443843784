import React, { useState } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  Button,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Container,
  Divider,
  CardContent,
  Select,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Colors from "../../../../assets/styles";

function UserManagement() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const users = [
    {
      name: "Inverness McKenzie",
      email: "invernessmckenzie@xyz.com",
      role: "Administrator",
      dateAdded: "July 04, 2022",
      lastActive: "July 04, 2022",
      status: "active",
    },
    {
      name: "Chaplain Mondover",
      email: "chaplainmondover@xyz.com",
      role: "Administrator",
      dateAdded: "July 04, 2022",
      lastActive: "July 04, 2022",
      status: "active",
    },
    {
      name: "Hannah McKenzie",
      email: "hannahmckenzie@xyz.com",
      status: "pending",
    },
    {
      name: "Luis Litt",
      email: "luislitt@xyz.com",
      status: "pending",
    },
    // Add more users...
  ];

  return (
    <>
  
    <Grid container gap="70px" sx={{ mt: 10 }}>
      <Grid item md={12} paddingLeft={4}>
        <Box>
       
          <Typography
            variant="h6"
            sx={{
              color: Colors.primary,
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Settings
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "#A9B9C6", fontSize: "14px", mb: 2 }}
          >
            Manage your team members and their account permission here
          </Typography>
        </Box>
      </Grid>
      <Grid item md={3} paddingLeft={4}>
        <Typography variant="h6" fontWeight="bold" marginBottom={1}>
          Users
        </Typography>
        <Typography  variant="h5"
            sx={{ color: "#A9B9C6", fontSize: "15px", mb: 2 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nisl
          felis, efficitur nec leo sed, tincidunt fermentum sapien. Aenean nec
          felis ac augue auctor vulputate. Cras pretium diam. Aenean feugiat
          tellus lacus, ac volutpat nibh sollicitudin ut. Duis sed nisi dictum,
          tempor purus in, consequat felis.
        </Typography>
        <Box
          sx={{
            color: Colors.white,
            background: Colors.black,
            padding: "5px 22px",
            borderRadius: "25px",
            cursor: "pointer",
            width: "85px",
          }}
        >
          Invite User
        </Box>
        
      </Grid>
      <Grid item md={8} xs={12} >
        <Card variant="outlined" style={{ marginBottom: "16px" ,background:"none"}}>
          <CardContent>
            <Grid container alignItems="center" spacing={2} marginBottom={2}>
              <Grid item>
                <Avatar
                  src="https://placehold.co/50x50"
                  alt="Profile picture of Inverness McKenzie"
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body1" fontWeight="bold">
                  Inverness McKenzie
                </Typography>
                <Typography variant="body2">
                  invernessmckenzie@xyz.com
                </Typography>
              </Grid>
              <Grid item>
                <Select
                  defaultValue="Administrator"
                  variant="outlined"
                  size="small"
                >
                  <MenuItem value="Administrator">Administrator</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Date Added:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                  <br />
                  Last Active:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                </Typography>
              </Grid>
              <Grid item>
                <i class="fas fa-ellipsis-v"></i>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              alignItems="center"
              spacing={2}
              marginBottom={2}
              marginTop={2}
            >
              <Grid item>
                <Avatar
                  src="https://placehold.co/50x50"
                  alt="Profile picture of Chaplain Mondover"
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body1" fontWeight="bold">
                  Chaplain Mondover
                </Typography>
                <Typography variant="body2">
                  chaplainmondover@xyz.com
                </Typography>
              </Grid>
              <Grid item>
                <Select
                  defaultValue="Administrator"
                  variant="outlined"
                  size="small"
                >
                  <MenuItem value="Administrator">Administrator</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Date Added:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                  <br />
                  Last Active:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                </Typography>
              </Grid>
              <Grid item>
                <i class="fas fa-ellipsis-v"></i>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              alignItems="center"
              spacing={2}
              marginBottom={2}
              marginTop={2}
            >
              <Grid item>
                <Avatar
                  src="https://placehold.co/50x50"
                  alt="Profile picture of Joniva Pearson"
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body1" fontWeight="bold">
                  Joniva Pearson
                </Typography>
                <Typography variant="body2">jonivapearson@xyz.com</Typography>
              </Grid>
              <Grid item>
                <Select
                  defaultValue="Administrator"
                  variant="outlined"
                  size="small"
                >
                  <MenuItem value="Administrator">Administrator</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Date Added:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                  <br />
                  Last Active:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                </Typography>
              </Grid>
              <Grid item>
                <i class="fas fa-ellipsis-v"></i>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              alignItems="center"
              spacing={2}
              marginBottom={2}
              marginTop={2}
            >
              <Grid item>
                <Avatar
                  src="https://placehold.co/50x50"
                  alt="Profile picture of Jessica Pearson"
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body1" fontWeight="bold">
                  Jessica Pearson
                </Typography>
                <Typography variant="body2">jessicapearson@xyz.com</Typography>
              </Grid>
              <Grid item>
                <Select
                  defaultValue="Administrator"
                  variant="outlined"
                  size="small"
                >
                  <MenuItem value="Administrator">Administrator</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Date Added:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                  <br />
                  Last Active:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                </Typography>
              </Grid>
              <Grid item>
                <i class="fas fa-ellipsis-v"></i>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              alignItems="center"
              spacing={2}
              marginBottom={2}
              marginTop={2}
            >
              <Grid item>
                <Avatar
                  src="https://placehold.co/50x50"
                  alt="Profile picture of Monica Overlinks"
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body1" fontWeight="bold">
                  Monica Overlinks
                </Typography>
                <Typography variant="body2">monicaoverlinks@xyz.com</Typography>
              </Grid>
              <Grid item>
                <Select
                  defaultValue="Administrator"
                  variant="outlined"
                  size="small"
                >
                  <MenuItem value="Administrator">Administrator</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Date Added:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                  <br />
                  Last Active:{" "}
                  <span style={{ fontWeight: "bold" }}>July 04, 2022</span>
                </Typography>
              </Grid>
              <Grid item>
                <i class="fas fa-ellipsis-v"></i>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
     
        
      </Grid>
    </Grid>
       <Grid container gap="70px">
       <Grid item md={3} paddingLeft={4}>
       <Typography variant="h6" fontWeight="bold" marginBottom={1}>
     Pending Invite
   </Typography>
   <Typography  variant="h5"
       sx={{ color: "#A9B9C6", fontSize: "15px", mb: 2 }}>
     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nisl
     felis, efficitur nec leo sed, tincidunt fermentum sapien. Aenean nec
     felis ac augue auctor vulputate. Cras pretium diam. Aenean feugiat
     tellus lacus, ac volutpat nibh sollicitudin ut. Duis sed nisi dictum,
     tempor purus in, consequat felis.
   </Typography>
       </Grid>
       <Grid item md={8} xs={12}>
       <Card variant="outlined" sx={{background:'none',mb:3}}>
     <CardContent>
       <Grid container alignItems="center" spacing={2} marginBottom={2}>
         <Grid item>
           <Avatar
             src="https://placehold.co/50x50"
             alt="Profile picture of Hannah McKenzie"
           />
         </Grid>
         <Grid item xs>
           <Typography variant="body1" fontWeight="bold">
             Hannah McKenzie{" "}
             <span style={{ color: "red" }}>(Pending)</span>
           </Typography>
           <Typography variant="body2">hannahmckenzie@xyz.com</Typography>
         </Grid>
         <Grid item>
           <Button variant="contained" style={{ marginRight: "8px" }}>
             Resend Invite
           </Button>
           <Button variant="contained">Revoke Invite</Button>
         </Grid>
       </Grid>
       <Divider />
       <Grid
         container
         alignItems="center"
         spacing={2}
         marginBottom={2}
         marginTop={2}
       >
         <Grid item>
           <Avatar
             src="https://placehold.co/50x50"
             alt="Profile picture of Luis Litt"
           />
         </Grid>
         <Grid item xs>
           <Typography variant="body1" fontWeight="bold">
             Luis Litt <span style={{ color: "red" }}>(Pending)</span>
           </Typography>
           <Typography variant="body2">luislitt@xyz.com</Typography>
         </Grid>
         <Grid item>
           <Button variant="contained" style={{ marginRight: "8px" }}>
             Resend Invite
           </Button>
           <Button variant="contained">Revoke Invite</Button>
         </Grid>
       </Grid>
     </CardContent>
   </Card>
       </Grid>
   </Grid>
   </>
  );
}

export default UserManagement;
