import React from 'react';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';

// Chart.js setup
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
);

const SalesChart = () => {
  // Data for the chart
  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Gross Sales',
        data: [100, 200, 150, 300, 250, 400, 350],
        tension: 0.4,
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false, // Hide X axis
      },
      y: {
        display: false, // Hide Y axis
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: 2,
        textAlign: 'center',
      }}
    >
     
      <Line data={data} options={options} height={90} />
    </Box>
  );
};

export default SalesChart;
