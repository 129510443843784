import  {Svgs} from "./app/assets/images/index";
const Navigation = [
  {
    name: "Home",
    icon: Svgs.home,
    path:"/dashboard"
   
  },

  // {
  //   name: "Create Skill",
  //   icon: Svgs.employees,
  //   path:"/create/skill",    
  // },
  {
    name: "UnAssigned Tickets",
    icon: Svgs.ticket,
    path:"/tickets/unassigned",    
  },
  {
    name: "Assigned Tickets",
    icon: Svgs.ticket,
    path:"/tickets/assigned",    
  },
  {
    name: "Users",
    icon: Svgs.person,
    path:"/users",    
  },
  {
    name: "Properties",
    icon: Svgs.puzzle,
    // path:"/properties",    
  },
  {
    name: "Chat",
    icon: Svgs.message,
    path:"/chat",    
  },
  {
    name: "Report",
    icon: Svgs.report,
    path:"/report",    
  },
  // {
  //   name: "Application",
  //   icon: <Equalizer />,
  //   subMenu: [
  //     {
  //       name: "Kanban",
  //       icon: <Circle />,
  //       path: "/kanban"
  //     },
  //     {
  //       name: "Wizard",
  //       icon: <Circle />,
  //       path: "/wizard"
  //     },
  //     {
  //       name: "Calendar",
  //       icon: <Circle />,
  //       path: "/calendar"
  //     },
  //   ]
  // },
  // {
  //   name: "Ecommerce",
  //   icon: <ShoppingCart />,
  //   subMenu: [
  //     {
  //       name: "Products",
  //       icon: <Circle />,
  //       path: "/products"
  //     },
  //     {
  //       name: "Others",
  //       icon: <Circle />,
  //       path: "/others"
  //     },
  //   ]
  // },
  // {
  //   name: "Authentication",
  //   icon: <Person />,
  //   subMenu: [
  //     {
  //       name: "Sign In",
  //       icon: <Circle />,
  //       path: "/signin"
  //     },
  //     {
  //       name: "Sign Up",
  //       icon: <Circle />,
  //       path: "/signup"
  //     },
  //   ]
  // },
];

export default Navigation;