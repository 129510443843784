// src/components/Dashboard.js
import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Paper,
  Link,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import GroupIcon from "@mui/icons-material/Group";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PhoneIcon from "@mui/icons-material/Phone";
import ChatIcon from "@mui/icons-material/Chat";
import PersonAddCheckIcon from "@mui/icons-material/PersonAddAlt1";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PieChartIcon from "@mui/icons-material/PieChart";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimerIcon from "@mui/icons-material/Timer";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import PollIcon from "@mui/icons-material/Poll";
import { Images } from "../../../assets/images";
import Colors from "../../../assets/styles";

const Dashboard = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: "16px",
        paddingRight: "0px !important",
        mt: 10,
        gap: "30px",
      }}
    >
      <Grid container spacing={4} sx={{ gap: "30px" }}>
        <Grid
          item
          md={7.63}
          xs={12}
          padding={4}
          sx={{ background: Colors.white, mt: 4 }}
        >
          <Box marginBottom={3}>
            <TextField
              variant="outlined"
              placeholder="Ask me a question about your Helpdesk"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box marginBottom={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Helpdesk Analysic
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                   <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportUser} />
                  </Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    HELPDESK IN-DEPTH
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportTicket} alt="Report Ticket" />
                  </Box>

                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    TICKET VOLUME TRENDS
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                   <Box
                   
                  >
                    <img src={Images.reportPhone} />
                  </Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    PHONE SUMMARY
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportComment} />
                  </Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    CHAT SUMMARY
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ border: `1px solid ${Colors.textGrey}` }} />
          <Box sx={{ my: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Productivity
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportAgent} />
                  </Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    AGENT PERFORMANCE
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                   <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportGroup} />
                  </Box>

                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    GROUP PERFORMANCE
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                 <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportPerformance} />
                  </Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    PERFORMANCE DISTRIBUTION
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                   <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportTime} />
                  </Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    TIME SHEET SUMMARY
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                   <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportLifeCycle} />
                  </Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    TICKET LIFECYCLE
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Divider sx={{ border: `1px solid ${Colors.textGrey}` }} />

            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", mt: 3 }}
            >
              Customer Happiness
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                   <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportTopCustomer} />
                  </Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    TOP CUSTOMER ANALYSIS
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2} xs={6}>
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                   <Box
                    sx={{
                      img: {
                        "&:hover": {
                          boxShadow: "0px 5px 5px -2px grey",
                        },
                      },
                    }}
                  >
                    <img src={Images.reportSatisfaction} />
                  </Box>
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    SATISFACTION SURVEY
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={4}>
            <Box sx={{ color: Colors.textGrey }}>
              Want to build custom reports?
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={4}
          xs={12}
          paddingLeft={2}
          style={{ backgroundColor: "#ffffff", padding: "24px" }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Today's Insights
            </Typography>
            <Box sx={{ fontWeight: "bold", cursor: "pointer" }}>Customize</Box>
          </Box>
          <Typography
            sx={{ color: Colors.textGrey, fontSize: "13px" }}
            gutterBottom
          >
            Last Updated 27 Minutes ago
          </Typography>

          <Box marginTop={3}>
            <Paper
              style={{
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "none",
                border: `1px solid ${Colors.textGrey}`,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  365{" "}
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    Received Tickets
                  </Typography>
                </Typography>
                <Typography variant="body2">▲ 35%</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" marginTop={1}>
                Many modern alternatives often incorporate humor or other
                content that actually purpose of filler.
              </Typography>
            </Paper>
            <Paper
              style={{
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "none",
                border: `1px solid ${Colors.textGrey}`,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  120{" "}
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    Resolved Tickets
                  </Typography>
                </Typography>
                <Typography variant="body2" style={{ color: "#f44336" }}>
                  ▼ 27%
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" marginTop={1}>
                All Tickets and no break? Consider staffing up to resolve more
                issue high fives, team i ssuper responsive todays!
              </Typography>
            </Paper>
            <Paper
              style={{
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "none",
                border: `1px solid ${Colors.textGrey}`,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  2h 15m{" "}
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    Average Response time
                  </Typography>
                </Typography>
                <Typography variant="body2">▲ 20%</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" marginTop={1}>
                Surprisingly, there is a very vocal faction of the design
                community that wants to see filler text banished to the original
                sources. C/O https://placeholder.com/text/
              </Typography>
            </Paper>
            <Paper
              style={{
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "none",
                border: `1px solid ${Colors.textGrey}`,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  15h 25m{" "}
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    Average Resolution Time
                  </Typography>
                </Typography>
                <Typography variant="body2">▲ 32%</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" marginTop={1}>
                Your team is on fire today
              </Typography>
            </Paper>
            <Paper
              style={{
                padding: "16px",
                marginBottom: "16px",
                boxShadow: "none",
                border: `1px solid ${Colors.textGrey}`,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  30m 14s{" "}
                  <Typography
                    variant="body2"
                    component="span"
                    color="textSecondary"
                  >
                    Average first assign Time
                  </Typography>
                </Typography>
                <Typography variant="body2" style={{ color: "#f44336" }}>
                  ▼ 26%
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" marginTop={1}>
                Those opposed to using filler text of any sort counter by
                saying: The ultimate purpose of any digital product.
              </Typography>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
