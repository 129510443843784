export const  AuthRoutes = {
   
    login: "/auth/login",
    logout: "auth/logout",
    changePassword:"/auth/changePassword",
    getQrCode :"/auth/getQRCode",
    verifyCode:"/auth/twoFactorStatus",
    getTwoFactorStatus:"/auth/getTwoFactorStatus",
    generatePasskeyChallenge:"/auth/generatePasskeyChallenge",
    verifyPasskeyRegistration:"/auth/verifyPasskeyRegistration",
    loginPassKeyChallenge:"/auth/loginPassKeyChallenge",
    checkPassKeys:"/auth/checkPassKeys",
    loginByPassKey:"/auth/loginByPassKey",
    getPassKeys:"/auth/getPassKeys",
    updatePassKeys:"/auth/updatePassKey",
    deletePassKeys:"/auth/deletePassKey",
    getRegisteredDevice:"/auth/getRegisteredDevices",
    deleteRegisteredDevice:"/auth/deleteRegisteredDevice",
  };
    