import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  IconButton,
} from "@mui/material";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  LineElement
} from "chart.js";
import Colors from "../../../assets/styles";
import { Images } from "../../../assets/images";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Flag from "react-flagkit";
import SalesChart from "../../../components/Charts/SaleChart";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MovingIcon from "@mui/icons-material/Moving";
ChartJS.register(BarElement,LineElement, CategoryScale, LinearScale, PointElement, Tooltip);
const Dashboard = () => {
 
  // Data for Bar Chart
  const barData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Tickets Solved",
        data: [50, 40, 60, 30, 50, 70, 45],
        backgroundColor: "#E0E0E0",
        borderRadius: 5,
        barThickness: 15,
      },
    ],
  };

  // Options for the Bar Chart
  const barOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
  };

  const tickets = [
    {
      id: 27,
      payer: "Rodney Archibald",
      createdBy: "Chaplain Mordeaux",
      insurance: "6778056",
      caseRef: "325",
      claimantName: "Fergus Douchbag",
      dateSubmitted: "29 Oct 2018",
      dueDate: "28 Oct 2018",
      assignee: "Chaplain Mordeaux",
      status: "In Progress",
    },
    {
      id: 27,
      payer: "Rodney Archibald",
      createdBy: "Chaplain Mordeaux",
      insurance: "6778056",
      caseRef: "325",
      claimantName: "Fergus Douchbag",
      dateSubmitted: "29 Oct 2018",
      dueDate: "28 Oct 2018",
      assignee: "Chaplain Mordeaux",
      status: "In Progress",
    },
    {
      id: 27,
      payer: "Rodney Archibald",
      createdBy: "Chaplain Mordeaux",
      insurance: "6778056",
      caseRef: "325",
      claimantName: "Fergus Douchbag",
      dateSubmitted: "29 Oct 2018",
      dueDate: "28 Oct 2018",
      assignee: "Chaplain Mordeaux",
      status: "In Progress",
    },
    {
      id: 27,
      payer: "Rodney Archibald",
      createdBy: "Chaplain Mordeaux",
      insurance: "6778056",
      caseRef: "325",
      claimantName: "Fergus Douchbag",
      dateSubmitted: "29 Oct 2018",
      dueDate: "28 Oct 2018",
      assignee: "Chaplain Mordeaux",
      status: "In Progress",
    },
    {
      id: 27,
      payer: "Rodney Archibald",
      createdBy: "Chaplain Mordeaux",
      insurance: "6778056",
      caseRef: "325",
      claimantName: "Fergus Douchbag",
      dateSubmitted: "29 Oct 2018",
      dueDate: "28 Oct 2018",
      assignee: "Chaplain Mordeaux",
      status: "In Progress",
    },
    // Other tickets...
  ];
  const tableHead = [
    "ID",
    "Payer",
    "Created By",
    "Insurance",
    "Case Ref",
    "Claimant Name",
    "Date Submitted",
    "Due Date",
    "Assignee",
    "Status",
    "Type",
    "Action",
  ];
  const locationFlags = {
    us: "US",
    de: "DE",
    cn: "CN",
    au: "AU",
  };

  const mostCustomer = [
    {
      name: "Spruce Springclean",
      tickets: "17 Tickets",
      location: "us",
      lastReply: "01:52PM",
    },
    {
      name: "Archibald Northbottom",
      tickets: "24 Tickets",
      location: "de",
      lastReply: "03:22PM",
    },
    {
      name: "Rodney Artichoke",
      tickets: "26 Tickets",
      location: "cn",
      lastReply: "04:10PM",
    },
    {
      name: "Gustav Purpleson",
      tickets: "21 Tickets",
      location: "au",
      lastReply: "06:12PM",
    },
  ];

  // Data for the chart
  const data = {
    labels: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ],
    datasets: [
      {
        label: 'Tickets Solved',
        data: [50, 100, 200, 150, 250, 100, 150, 200, 100, 150, 250, 200],
        tension: 0.4,
        borderColor: '#000000',
        borderWidth: 2,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: '#000000',
        pointRadius: 5,
        pointHoverRadius: 7,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return ` ${tooltipItem.raw} Tickets`;
          },
        },
      },
      // Custom plugin to draw the vertical line
      afterDraw: (chart) => {
        const ctx = chart.ctx;
        const dataset = chart.getDatasetMeta(0);
        const { data: points } = dataset;

        // Assume the line should be drawn at the point for May (index 4)
        const targetIndex = 4;
        const targetPoint = points[targetIndex];

        // Get the bottom position (aligned with x-axis)
        const bottomY = chart.scales.y.bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(targetPoint.x, bottomY);
        ctx.lineTo(targetPoint.x, targetPoint.y);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'black';
        ctx.stroke();
        ctx.restore();

        // Add the label above the point
        ctx.font = 'bold 12px Arial';
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.fillText(
          `${data.datasets[0].data[targetIndex]}`,
          targetPoint.x,
          targetPoint.y - 10
        );
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: '2018 Years',
          color: '#A0A0A0',
          font: {
            size: 14,
          },
        },
      },
      y: {
        display: true,
        title: {
          display: false,
        },
        ticks: {
          color: '#A0A0A0',
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        borderColor: '#000000',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 10 }}>
     
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <CssBaseline />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                color: Colors.primary,
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              Ticket Summary{" "}
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: "#A9B9C6", fontSize: "15px", mb: 2 }}
            >
              The Updates about the Support Ticket
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2.4} sm={6}>
                <Card
                  sx={{
                    height: "190px",
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={Images.file1} alt="file1" />
                    <Typography
                      variant="h5"
                      sx={{ color: Colors.primary, fontWeight: "600", mt: 2 }}
                    >
                      250
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#A9B9C6", fontSize: "15px" }}
                    >
                      Total No of Tickets
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={2.4} sm={6}>
                <Card
                  sx={{
                    height: "190px",
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={Images.file2} alt="file2" />
                    <Typography
                      variant="h5"
                      sx={{ color: Colors.primary, fontWeight: "600", mt: 2 }}
                    >
                      48
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#A9B9C6", fontSize: "15px" }}
                    >
                      Urgent Tickets
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={2.4} sm={6}>
                <Card
                  sx={{
                    height: "190px",
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={Images.file3} alt="file3" />
                    <Typography
                      variant="h5"
                      sx={{ color: Colors.primary, fontWeight: "600", mt: 2 }}
                    >
                      98
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#A9B9C6", fontSize: "15px" }}
                    >
                      New Tickets
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={2.4} sm={6}>
                <Card
                  sx={{
                    height: "190px",
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      p: 0,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.textGrey,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Gross Sales{" "}
                        <MovingIcon
                          sx={{
                            fontSize: "20px",
                            marginLeft: "5px",
                            color: Colors.black,
                          }}
                        />
                      </Typography>
                      <Typography
                        sx={{
                          color: Colors.textGrey,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        This Week{" "}
                        <KeyboardArrowDownIcon sx={{ fontSize: "18px" }} />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                        padding: "0px 20px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          color: "#2D3748",
                          marginTop: 1,
                        }}
                      >
                        $124.01
                      </Typography>
                      <Typography sx={{ color: Colors.textGrey }}>
                        $254
                      </Typography>
                    </Box>
                    <SalesChart />
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                //  md={4.5}
                md={2.4}
                sm={6}
              >
                <Card
                  sx={{
                    height: "190px",
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "#A9B9C6", fontSize: "15px" }}
                    >
                      Tickets Solved Per Month
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ color: Colors.primary, fontWeight: "600" }}
                    >
                      756
                    </Typography>
                    <Box sx={{ height: 80, mt: 2 }}>
                      <Bar data={barData} options={barOptions} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/* //super admin// */}
          <Grid item xs={12} md={7}>
      <Card
        sx={{
          boxShadow: 'none',
          borderRadius: '10px',
        }}
      >
        <CardContent
          sx={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Number of Solved Tickets
            </Typography>
            <Typography
              variant="caption"
              sx={{
                mt: 2,
                display: 'block',
                textAlign: 'right',
                color: '#A0A0A0',
              }}
            >
              Last 01 Years
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              width: '100%',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 1 }}>
              1250
            </Typography>
            <Typography variant="body2" sx={{ color: '#A0A0A0', mb: 2 }}>
              Tickets Solved
            </Typography>
          </Box>
          <Line data={data} options={options} height={90} />
        </CardContent>
      </Card>
    </Grid>
         <Grid item xs={12} md={5}>
            <Box sx={{ p:2,borderRadius:"10px" ,background:Colors.white }}>
              <Typography variant="h6" sx={{ fontWeight: "bold"}}>
                Customer with most Tickets
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: Colors.textGrey }}>
                        Customer Name
                      </TableCell>
                      <TableCell sx={{ color: Colors.textGrey }}>
                        Tickets
                      </TableCell>
                      <TableCell sx={{ color: Colors.textGrey }}>
                        Location
                      </TableCell>
                      <TableCell sx={{ color: Colors.textGrey }}>
                        Last Reply
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mostCustomer.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ fontWeight: "bold",p:1.5 }}>
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold",p:1.5 }}>
                          {row.tickets}
                        </TableCell>
                        <TableCell sx={{p:1.5}}>
                          <Flag country={locationFlags[row.location]} />
                        </TableCell>
                        <TableCell
                          sx={{ color: Colors.textGrey, fontWeight: "bold",p:1.5 }}
                        >
                          {row.lastReply}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ textAlign: "right", mt: 2 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#1A202C",
                    cursor: "pointer",
                  }}
                >
                  View All
                </Typography>
              </Box>
            </Box>
          </Grid>
 {/* //end superadmin */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                color: Colors.primary,
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              Urgent Tickets{" "}
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: "#A9B9C6", fontSize: "15px", mb: 2 }}
            >
              Latest Ticket opened by Customer
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHead.map((header, index) => (
                      <TableCell
                        key={index}
                        sx={{ color: Colors.textGrey, whiteSpace: "nowrap" }}
                      >
                        <Typography
                          sx={{ fontSize: "13px", fontWeight: "bold" }}
                        >
                          {header}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets.map((ticket, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        #{ticket.id}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.payer}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.createdBy}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.insurance}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.caseRef}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.claimantName}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.dateSubmitted}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.dueDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.assignee}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          px: "10px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Typography
                          sx={{
                            color: Colors.yellow,
                            fontWeight: "bold",
                            fontSize: "13px",
                            background: "#f4e7d3",
                            display: "inline-block",
                            paddingY: "5px",
                            paddingX: "7px",
                          }}
                        >
                          {ticket.status}
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        -
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          py: 3,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Box sx={{ display: "flex", fontSize: "13px" }}>
                          <IconButton sx={{ p: 0.6 }}>
                            <RemoveRedEyeIcon sx={{ fontSize: "15px" }} />
                          </IconButton>
                          <IconButton sx={{ p: 0.6 }}>
                            <ModeEditIcon sx={{ fontSize: "15px" }} />
                          </IconButton>
                          <IconButton sx={{ p: 0.6 }}>
                            <DeleteIcon sx={{ fontSize: "15px" }} />
                          </IconButton>
                          <IconButton sx={{ p: 0.6 }}>
                            <MoreVertIcon sx={{ fontSize: "15px" }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ p: 3 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Customer with most Tickets
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: Colors.textGrey }}>
                        Customer Name
                      </TableCell>
                      <TableCell sx={{ color: Colors.textGrey }}>
                        Tickets
                      </TableCell>
                      <TableCell sx={{ color: Colors.textGrey }}>
                        Location
                      </TableCell>
                      <TableCell sx={{ color: Colors.textGrey }}>
                        Last Reply
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mostCustomer.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {row.tickets}
                        </TableCell>
                        <TableCell>
                          <Flag country={locationFlags[row.location]} />
                        </TableCell>
                        <TableCell
                          sx={{ color: Colors.textGrey, fontWeight: "bold" }}
                        >
                          {row.lastReply}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ textAlign: "right", mt: 2 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#1A202C",
                    cursor: "pointer",
                  }}
                >
                  View All
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;
