import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  Avatar,
  Paper,
  Divider,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import Colors from "../../../assets/styles";
import SimpleDialog from "../../../components/Dialog/index";
import { QRCodeSVG } from "qrcode.react";
import AuthServices from "../../../api/AuthServices/auth.index";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import { useForm } from "react-hook-form";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import ProfileServices from "../../../api/ProfileServices";
import { useAuth } from "../../../context";
import CheckIcon from "@mui/icons-material/Check";
import { DesktopWindows, Smartphone, TabletMac } from "@mui/icons-material";
import LaptopIcon from "@mui/icons-material/Laptop";
import {
  VerticalAlignBottom,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Images } from "../../../assets/images";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FileServices from "../../../api/FileServices/file.index";

export default function Profile() {
  const [qrDialog, setQrDialog] = useState(false);
  const [factorStatus, setFactorStatus] = useState(false);
  const [passkeyDialog, setPasskeyDialog] = useState(false);
  const [passkeyId, setPasskeyId] = useState("");
  const [deletePasskey, setDeletePasskey] = useState(false);
  const [editPassKeyDialog, setEditPassKeyDialog] = useState(false);
  const [openRevoke, setOpenRevoke] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [passkeyList, setPasskeyList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [qrCode, setQrCode] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [imageURL, setImageURL] = useState(false);

  const { user } = useAuth();
  const fcmToken = localStorage.getItem("fcmToken");
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log(user);
  console.log(factorStatus);
  const [profileData, setProfileData] = useState({
    id: "",
    name: " ",
    email: "",
    role: "",
    phone: "",
    image: "",
  });
  const [editingField, setEditingField] = useState(null);
  const [formData, setFormData] = useState(profileData);
  const [fingerprint, setFingerprint] = useState("");

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      console.log(fp);
      const result = await fp.get();
      console.log(result);
      setFingerprint(result.visitorId); 
      getTwoFactorStatus(result.visitorId)
    };
    loadFingerprint();
  }, []);

  const userAgent = navigator.userAgent;
  console.log(userAgent);
  const getDeviceType = () => {
    if (/Mobi|Android/i.test(userAgent)) {
      return "Mobile";
    } else if (/Tablet|iPad/i.test(userAgent)) {
      return "Tablet";
    } else {
      return "Desktop";
    }
  };
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    control: control2,
    getValues: getValues2,
    formState: { errors: errors2 },
  } = useForm();
  
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    setValue: setValue3,
    control: control3,
    getValues: getValues3,
    formState: { errors: errors3 },
    setError: setError3,
    clearErrors: clearErrors3,
    watch: watch3,
  } = useForm();

  const oldPassword = watch3("old_password");
  const newPassword = watch3("new_password");
  const confirm_password = watch3("confirm_password");

  useEffect(() => {
    if (newPassword && newPassword === oldPassword) {
      setError3("new_password", {
        type: "manual",
        message: "Old Password and New Password cannot be the same",
      });
    } else {
      clearErrors3("new_password");
    }

    if (confirm_password && newPassword !== confirm_password) {
      setError3("confirm_password", {
        type: "manual",
        message: "New Password and Confirm Password must be the same",
      });
    } else {
      clearErrors3("confirm_password");
    }
  }, [newPassword, oldPassword, confirm_password, setError3, clearErrors3]);

  const changePassword = async (formData) => {
    try {
      let obj = {
        oldPassword: formData.old_password,
        newPassword: formData.new_password,
      };
      console.log("Form submitted", obj);
      let result;
      result = await AuthServices.changePassword(obj);

      SuccessToaster(result?.message);
      setValue("old_password", "");
      setValue("new_password", "");
      setValue("confirm_password", "");
      setPasswordDialog(false);
    } catch (error) {
      ErrorToaster(error);
    } finally {
    }
  };

  const handleEditClick = async (field) => {
    if (editingField === field) {
      setProfileData((prevData) => ({
        ...prevData,
        [field]: formData[field],
      }));

      console.log("Updated Profile:", {
        ...profileData,
        [field]: formData[field],
      });

      try {
        const { data } = await ProfileServices.updateProfile({
          ...profileData,
          [field]: formData[field],
        });
        getProfileData();
      } catch (error) {
        ErrorHandler(error);
        console.log("Fetch error:", error?.message);
      }

      setEditingField(null);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: profileData[field],
      }));
      setEditingField(field);
    }
  };

  function resizeBase64Image(base64Str, maxWidth, maxHeight) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        // Maintain aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        const newBase64Str = canvas.toDataURL("image/png");
        resolve(newBase64Str);
      };
    });
  }
  const getQR = async () => {
    setQrDialog(false);
    // setLoading(true);
    try {
      const { data } = await AuthServices.getQrCode();
      setSecretKey(data?.secret);

      if (data.dataUrl) {
        resizeBase64Image(data.dataUrl, 180, 180).then((resizedImage) => {
          setQrCode(resizedImage);
        });
      }
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };
  const getProfileData = async () => {
    try {
      const { data } = await ProfileServices.getProfile(user?.user?._id);
      setProfileData(data?.user);
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };
  const getTwoFactorStatus = async (deviceKey) => {
    try {
      const { data } = await AuthServices.getTwoFactorStatus(deviceKey);
      setFactorStatus(data?.status);
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };
  useEffect(() => {
    // getTwoFactorStatus();
    getProfileData();
  }, []);

  const verifyQrCode = async (formData) => {
    const deviceType = getDeviceType();

    const obj = {
      token: formData?.token,
      device_name: formData?.deviceName,
      device_key: fingerprint,
      secret_key: secretKey,
      device_type: deviceType,
      status: true,
    };
    console.log(obj);
    try {
      const { data, message } = await AuthServices.verifyCode(obj);
      SuccessToaster(message);
      setQrDialog(false);
      getTwoFactorStatus();
      getRegisteredDevice();
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error);
    }
  };

  const changeStatus = async () => {
    const obj = {
      status: false,
    };
    const { data, message } = await AuthServices.verifyCode(obj);
    console.log(data);
    getTwoFactorStatus();
  };
  const userLogout = async () => {
    const obj = {
      user_id: user.user._id,
      fcmToken: fcmToken,
    };
    try {
      const data = await AuthServices.logout(obj);
      SuccessToaster(data.message);
      localStorage.removeItem("user");
      localStorage.removeItem("fcmToken");
      navigate("/login");
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error);
    }
  };
  // for Passkey code
  async function registerPasskey() {
    try {
      // Step 1: Get the registration options (challenge) from the backend
      const response = await AuthServices.generatePasskeyChallenge();
      const { options } = response.data;

      // Ensure that userID, userName, and challenge exist
      if (!options.user || !options.user.id || !options.challenge) {
        throw new Error("Missing required options for passkey registration.");
      }

      // Convert user.id and challenge from base64url format to Uint8Array
      const userID = base64urlToUint8Array(options.user.id);
      const challenge = base64urlToUint8Array(options.challenge);

      // Construct the publicKey object
      const publicKey = {
        ...options,
        user: {
          id: userID,
          name: options.user.name,
          displayName: options.user.displayName || options.user.name,
        },
        challenge: challenge,
      };

      console.log("publicKey:", publicKey);

      // Step 2: Ask the user to enter a device name
      const deviceName = prompt(
        "Please enter a name for your device (e.g., 'My Laptop', 'iPhone'):"
      );

      // Step 3: Call navigator.credentials.create() to create a passkey
      let publicKeyCredential;
      try {
        publicKeyCredential = await navigator.credentials.create({
          publicKey: publicKey,
        });
      } catch (err) {
        console.error("Error creating credentials:", err);
        ErrorToaster("Error creating passkey.");
        return;
      }

      const obj = {
        keyName: deviceName || "My PassKey", // Use the device name provided by the user
        credential: publicKeyCredential,
      };
      console.log(obj);

      // Step 4: Send the credential to the backend for verification
      const verificationResponse = await AuthServices.verifyPasskeyRegistration(
        obj
      );
      const verificationResult = verificationResponse.data;
      console.log(verificationResponse);

      if (verificationResponse.status === true) {
        SuccessToaster("Passkey registered successfully!");
        getPasskeys();
      } else {
        ErrorToaster("Passkey registration failed.");
      }
    } catch (error) {
      console.error("Passkey registration error:", error);
    }
  }

  // Helper function to convert base64url strings to Uint8Array
  function base64urlToUint8Array(base64url) {
    const base64 = base64url.replace(/-/g, "+").replace(/_/g, "/");
    const paddedBase64 = base64.padEnd(
      base64.length + ((4 - (base64.length % 4)) % 4),
      "="
    );
    const rawData = atob(paddedBase64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
  }

  const getPasskeys = async () => {
    try {
      const { data } = await AuthServices.getPassKeys();
      setPasskeyList(data?.passkeys);
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };
  const deleteMyPassKey = async () => {
    try {
      const data = await AuthServices.deletePassKeys(passkeyId?._id);
      SuccessToaster(data?.message);
      setDeletePasskey(false);
      getPasskeys();
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };

  const EditPasKey = async () => {
    const obj = {
      _id: passkeyId?._id,
      name: getValues2("name"),
    };
    try {
      const data = await AuthServices.updatePassKeys(obj);
      SuccessToaster(data?.message);
      setEditPassKeyDialog(false);
      getPasskeys();
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };
  const getRegisteredDevice = async () => {
    try {
      const { data } = await AuthServices.getRegisteredDevice();
      setDeviceList(data?.registeredDevices);
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };
  useEffect(() => {
    getPasskeys();
    getRegisteredDevice();
  }, []);

  const DeleteDevice = async () => {
    try {
      const data = await AuthServices.deleteRegisteredDevice(deviceId);
      SuccessToaster(data?.message);
      setOpenRevoke(false);
      getRegisteredDevice();
      getTwoFactorStatus();
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };
  const handleFileChange = async (e) => {
    try {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const response = await FileServices.uploadImage(formData);
      console.log(response);
      setImageURL(response?.data?.imageUrl);
      updateProfile(response?.data?.imageUrl);
      // update(response?.data?.path);
      console.log(response?.data?.path);
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message || "Failed to upload image");
    }
  };

  const updateProfile = async (newFile) => {
    const obj = {
      _id: profileData?._id,
      firstName: profileData?.firstName,
      lastName: profileData?.lastName,
      email: profileData?.email,
      phone: profileData?.phone,
      image: newFile ? newFile : profileData?.image,
    };
    console.log(obj);
    try {
      const { data } = await ProfileServices.updateProfile(obj);
      setImageURL("");
      getProfileData();
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };
  const deleteProfilePic = async () => {
    const obj = {
      _id: profileData?._id,
      firstName: profileData?.firstName,
      lastName: profileData?.lastName,
      email: profileData?.email,
      phone: profileData?.phone,
      image: "",
    };
    console.log(obj);
    try {
      const { data } = await ProfileServices.updateProfile(obj);
      setImageURL("");
      getProfileData();
    } catch (error) {
      ErrorHandler(error);
      console.log("Fetch error:", error?.message);
    }
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 12 }}>
        <Typography
          variant="h4"
          sx={{ fontSize: "22px", fontWeight: "bold" }}
          gutterBottom
        >
          Account Settings
        </Typography>
        <Typography
          sx={{ color: Colors.textGrey, fontSize: "13px" }}
          color="textSecondary"
        >
          Manage your Account and permissions here.
        </Typography>
        <Grid container spacing={4} style={{ marginTop: "8px" }}>
          <Grid item md={6} sm={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={5} sm={6} xs={12}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Avatar
                    src={
                      profileData?.image != ""
                        ? baseUrl + profileData?.image
                        : imageURL
                    }
                    alt="Profile Picture"
                    style={{ width: 64, height: 64 }}
                  />
                  <Box ml={2} sx={{ justifyContent: "flex-end" }}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "15px", fontWeight: "bold" }}
                    >
                      Profile Picture
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: Colors.textGrey }}
                    >
                      myprofile.jpeg
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                md={7}
                sm={6}
                xs={12}
                sx={{ justifyContent: "flex-end", p: 0 }}
              >
                <Box display="flex" mb={2} sx={{ justifyContent: "flex-end" }}>
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      id="upload-button-file"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="upload-button-file">
                      <Button
                        component="span"
                        sx={{
                          border: "1px solid #cdcecf",
                          fontSize: "10px",
                          color: Colors.black,
                          background: "#e8e9ea",
                          fontWeight: "bold",
                          marginRight: "1rem",
                        }}
                      >
                        Upload New Picture
                      </Button>
                    </label>
                  </div>
                  <Button
                    sx={{
                      border: "1px solid #EE1D52",
                      fontSize: "10px",
                      color: "#EE1D52",
                      background: "#f6e2e8",
                      fontWeight: "bold",
                    }}
                    onClick={deleteProfilePic}
                  >
                    Delete Profile Picture
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ marginTop: "1px" }}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold" }}
              gutterBottom
            >
              Information
            </Typography>
            <Box mb={2}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={6} container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                      First Name
                    </Typography>
                    {editingField === "firstName" ? (
                      <TextField
                        defaultValue={profileData.firstName}
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value)
                        }
                        fullWidth
                      />
                    ) : (
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="body1"
                        color="textPrimary"
                      >
                        {profileData.firstName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ mr: { md: 1, sm: 0, xs: 0 } }}>
                    <IconButton
                      size="small"
                      onClick={() => handleEditClick("firstName")}
                    >
                      {editingField === "firstName" ? (
                        <CheckIcon />
                      ) : (
                        <EditIcon />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider
                  sx={{
                    my: 2,
                    width: "100%",
                    display: { md: "none", sm: "block" },
                  }}
                />
                <Grid item xs={12} md={6} container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                      Last Name
                    </Typography>
                    {editingField === "lastName" ? (
                      <TextField
                        defaultValue={profileData.lastName}
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value)
                        }
                        fullWidth
                      />
                    ) : (
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="body1"
                        color="textPrimary"
                      >
                        {profileData.lastName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => handleEditClick("lastName")}
                    >
                      {editingField === "lastName" ? (
                        <CheckIcon />
                      ) : (
                        <EditIcon />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mb={2} mt={2}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body2" color="textSecondary">
                    Contact Email
                  </Typography>
                  {editingField === "email" ? (
                    <TextField
                      defaultValue={profileData.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      fullWidth
                    />
                  ) : (
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="body1"
                      color="textPrimary"
                    >
                      {profileData.email}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => handleEditClick("email")}
                  >
                    {editingField === "email" ? <CheckIcon /> : <EditIcon />}
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mb={2} mt={2}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body2" color="textSecondary">
                    Phone Number
                  </Typography>
                  {editingField === "phone" ? (
                    <TextField
                      defaultValue={profileData.phone}
                      onChange={(e) =>
                        handleInputChange("phone", e.target.value)
                      }
                      fullWidth
                    />
                  ) : (
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="body1"
                      color="textPrimary"
                    >
                      {profileData.phone}
                    </Typography>
                  )}
                </Grid>
                <Grid item sx={{ textAlign: "right" }}>
                  <IconButton
                    size="small"
                    onClick={() => handleEditClick("phone")}
                  >
                    {editingField === "phone" ? <CheckIcon /> : <EditIcon />}
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mb={2} mt={2}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body2" color="textSecondary">
                    Password
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    Last Changed{" "}
                    {moment(profileData?.updatePasswordAt).format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                  </Typography>
                </Grid>
                <Grid item sx={{ textAlign: "right" }}>
                  <IconButton
                    size="small"
                    onClick={() => setPasswordDialog(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={4} display="flex" sx={{ mb: 2 }}>
              <Button
                sx={{
                  border: "1px solid #cdcecf",
                  px: 3,
                  fontSize: "10px",
                  color: Colors.black,
                  background: "#e8e9ea",
                  fontWeight: "bold",
                  marginRight: "1rem",
                }}
                onClick={userLogout}
              >
                Log Out
              </Button>
              <Button
                sx={{
                  border: "1px solid #EE1D52",
                  fontSize: "10px",
                  color: "#EE1D52",
                  background: "#f6e2e8",
                  fontWeight: "bold",
                }}
              >
                Delete My Account
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold" }}
              component="h2"
              gutterBottom
            >
              Security
            </Typography>
            <Box
              mb={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { md: "row", sm: "row", xs: "column" },
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{ color: Colors.textGrey, fontSize: "15px" }}
                  color="textPrimary"
                >
                  Secure your Account
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: Colors.black, fontSize: "12px" }}
                  color="textSecondary"
                >
                  Enable two factor authentication to add an extra layer of
                  protection
                </Typography>
              </Box>
              {!factorStatus == true ? (
                <>
                  <Button
                    sx={{
                      border: "1px solid #48c741",
                      fontSize: "10px",
                      color: "#48c741",
                      background: "#e5f3e7",
                      fontWeight: "bold",
                      width: "100px",
                      mt: { md: 0, sm: 0, xs: 1 },
                    }}
                    onClick={() => {
                      getQR();
                      setQrDialog(true);
                    }}
                  >
                    Enable 2-FA
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    sx={{
                      border: "1px solid #EE1D52",
                      fontSize: "10px",
                      color: "#EE1D52",
                      background: "#f6e2e8",
                      fontWeight: "bold",
                      width: "100px",
                      mt: { md: 0, sm: 0, xs: 1 },
                    }}
                    onClick={() => {
                      changeStatus();
                    }}
                  >
                    Disable 2-FA
                  </Button>
                </>
              )}
            </Box>

            <Typography
              variant="h6"
              sx={{ fontWeight: "bold" }}
              component="h2"
              gutterBottom
            >
              Registered Devices
            </Typography>
            <Box mb={2}>
              {deviceList?.length > 0 ? (
                deviceList.map((item) => (
                  <Paper
                    key={item._id}
                    sx={{
                      padding: "1rem 0",
                      background: "none !important",
                      boxShadow: "none",
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={6} md={6}>
                        <Box display="flex" alignItems="center">
                          {item?.device_type === "Desktop" ? (
                            <LaptopIcon
                              sx={{
                                fontSize: 48,
                                marginRight: "1rem",
                                color: "primary.main",
                              }}
                            />
                          ) : item?.device_type === "Mobile" ? (
                            <Smartphone
                              sx={{
                                fontSize: 48,
                                marginRight: "1rem",
                                color: "primary.main",
                              }}
                            />
                          ) : item?.device_type === "Tablet" ? (
                            <TabletMac
                              sx={{
                                fontSize: 48,
                                marginRight: "1rem",
                                color: "primary.main",
                              }}
                            />
                          ) : (
                            <DesktopWindows
                              sx={{
                                fontSize: 48,
                                marginRight: "1rem",
                                color: "primary.main",
                              }}
                            /> // Default icon if device type is unknown
                          )}
                          <Box>
                            <Typography
                              sx={{ fontWeight: "bold" }}
                              variant="body1"
                              color="textPrimary"
                            >
                              {item?.device_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Last Active{" "}
                              {moment(item?.last_active).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{
                          display: "flex",
                          justifyContent: {
                            md: "flex-end",
                            sm: "flex-end",
                            xs: "flex-start",
                          },
                          mt: { md: 0, sm: 0, xs: 1 },
                        }}
                      >
                        <Button
                          sx={{
                            border: "1px solid #EE1D52",
                            fontSize: "10px",
                            color: "#EE1D52",
                            background: "#f6e2e8",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            setDeviceId(item?._id);
                            setOpenRevoke(true);
                          }}
                        >
                          Revoke Access
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                ))
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: "center", marginTop: "1rem" }}
                >
                  No registered device found.
                </Typography>
              )}
            </Box>
            <Box
              mb={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { md: "row", sm: "row", xs: "column" },
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{ color: Colors.textGrey, fontSize: "15px" }}
                  color="textPrimary"
                >
                  Secure your Account
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: Colors.black, fontSize: "12px" }}
                  color="textSecondary"
                >
                  Enable PassKey to add an extra layer of protection
                </Typography>
              </Box>

              <IconButton
                sx={{
                  color: "black",
                  fontSize: "10px",

                  fontWeight: "bold",
                  // width: "100px",
                  mt: { md: 0, sm: 0, xs: 1 },
                }}
                onClick={() => {
                  setPasskeyDialog(true);
                }}
              >
                {/* <KeyIcon sx={{ color: "#717272" }} /> */}
                <ArrowForwardIosIcon sx={{ color: "#717272" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* //qr code modal  */}
      {qrDialog && factorStatus == false && (
        <SimpleDialog
          open={qrDialog}
          onClose={() => {
            setQrDialog(false);
            reset({ token: "" });
          }}
          title="Scan QR Code "
        >
          {/* Display QR Code */}
          {qrCode && (
            <img
              height="300px"
              width="300px"
              style={{ margin: "0 auto", display: "block" }}
              src={qrCode}
              alt="Resized QR Code"
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: Colors.textGrey,
            }}
          >
            Scan By Google Authenticator App
          </Box>
          {/* Form Fields */}
          <Box
            component="form"
            onSubmit={handleSubmit(verifyQrCode)}
            sx={{ mt: 2 }}
          >
            {/* Token Field */}
            <InputLabel>Enter Device Name</InputLabel>
            <TextField
              type="text"
              placeholder="Enter Name"
              variant="outlined"
              fullWidth
              {...register("deviceName", {
                required: "Device Name is required",
              })}
              error={!!errors.deviceName}
              helperText={errors.deviceName ? errors.deviceName.message : ""}
              style={{ marginTop: "0px", width: "100%" }}
            />
            <InputLabel>Enter Token</InputLabel>
            <TextField
              type="text"
              placeholder="Enter Token"
              variant="outlined"
              fullWidth
              {...register("token", { required: "Token is required" })}
              error={!!errors.token}
              helperText={errors.token ? errors.token.message : ""}
              style={{ marginTop: "0px", width: "100%" }}
            />

            {/* Secret Key Field */}
            {/* <InputLabel sx={{ mt: 1 }}>Secret Key</InputLabel>
            <TextField
              type="text"
               value={secretKey}
              placeholder="Enter Secret Key"
              variant="outlined"
              disabled
              fullWidth
              style={{ marginTop: "0px", width: "100%" }}
            /> */}

            {/* Action Buttons */}
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}
            >
              <Button
                sx={{ border: "1px solid black" }}
                onClick={() => {
                  setQrDialog(false);
                  reset({ token: "" });
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </Box>
        </SimpleDialog>
      )}
      {/* ///passkey list modal  */}
      <SimpleDialog
        open={passkeyDialog}
        onClose={() => {
          setPasskeyDialog(false);
        }}
        // title="Passkey Settings "
      >
        <Box sx={{ width: "100%", maxWidth: 600, margin: "0 auto" }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
            Passkey settings
          </Typography>
          <List sx={{ overflowY: "auto", height: "300px" }}>
            {passkeyList.length === 0 ? (
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  padding: "16px",
                  color: "gray",
                  mt: 4,
                }}
              >
                No passkey available
              </Typography>
            ) : (
              passkeyList.map((item, index) => (
                <Box key={index}>
                  <ListItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "8px 16px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <KeyIcon />
                      <ListItemText
                        primary={item.name}
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              display="block"
                            >
                              Date created:{" "}
                              {moment(item.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              display="block"
                            >
                              Last used:{" "}
                              {moment(item.last_used).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </Typography>
                          </>
                        }
                        sx={{ marginLeft: 2 }}
                        primaryTypographyProps={{ fontWeight: "bold" }}
                        secondaryTypographyProps={{ fontSize: "0.875rem" }}
                      />
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        onClick={() => {
                          setPasskeyId(item);
                          setEditPassKeyDialog(true);
                        }}
                        edge="end"
                        aria-label="delete"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setPasskeyId(item);
                          setDeletePasskey(true);
                        }}
                        edge="end"
                        aria-label="delete"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                  {index < passkeyList.length - 1 && <Divider />}
                </Box>
              ))
            )}
          </List>

          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                borderRadius: "30px",
                padding: "10px 20px",
                textTransform: "none",
                fontWeight: "bold",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
              onClick={registerPasskey}
            >
              Create a new passkey
            </Button>
          </Box>
        </Box>
      </SimpleDialog>

      {/* //delete passkey modal  */}

      <SimpleDialog
        open={deletePasskey}
        onClose={() => {
          setDeletePasskey(false);
        }}
        // title="Scan QR Code "
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            color: Colors.textGrey,
          }}
        >
          Are You Sure you want To Delete This Pass key
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}>
          <Button
            sx={{ border: "1px solid black" }}
            onClick={() => {
              setDeletePasskey(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={deleteMyPassKey} variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </SimpleDialog>

      {/* edit passkey Dialog  */}
      <SimpleDialog
        open={editPassKeyDialog}
        onClose={() => {
          setEditPassKeyDialog(false);
        }}
        // title="Scan QR Code "
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            color: Colors.textGrey,
          }}
        >
          Edit Name Of Your Device
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit2(EditPasKey)}
          sx={{ mt: 2 }}
        >
          {/* Token Field */}
          <InputLabel>Enter Name</InputLabel>
          <TextField
            type="text"
            placeholder="Enter Name"
            variant="outlined"
            fullWidth
            defaultValue={passkeyId?.name}
            {...register2("name", { required: "Name is required" })}
            error={!!errors2.name}
            helperText={errors2.name ? errors2.name.message : ""}
            style={{ marginTop: "0px", width: "100%" }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}>
            <Button
              sx={{ border: "1px solid black" }}
              onClick={() => {
                setEditPassKeyDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </Box>
      </SimpleDialog>

      {/* ///Revoke device  */}
      <SimpleDialog
        open={openRevoke}
        onClose={() => {
          setOpenRevoke(false);
        }}
        // title="Scan QR Code "
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            color: Colors.textGrey,
          }}
        >
          Are You Sure you want To Revoke this Device
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}>
          <Button
            sx={{ border: "1px solid black" }}
            onClick={() => {
              setOpenRevoke(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={DeleteDevice} variant="contained" color="primary">
            Confirm
          </Button>
        </Box>
      </SimpleDialog>

      {/* //password Dialog  */}
      <SimpleDialog
        open={passwordDialog}
        onClose={() => {
          setPasswordDialog(false);
        }}
        // title="Scan QR Code "
      >
        <Box
          sx={{
            borderRadius: "10px",
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
            Change Password
          </Typography>
          <form onSubmit={handleSubmit3(changePassword)}>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item md={6} sx={{ width: "100%" }}>
                <Box sx={{ fontWeight: "500", fontSize: "14px", mb: 1 }}>
                  Old Password
                </Box>
                <TextField
                  type={showPassword ? "text" : "password"}
                  {...register3("old_password", {
                    required: "Please enter old password",
                  })}
                  error={Boolean(errors3.old_password)}
                  InputProps={{
                    style: { padding: "5px" },
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                  helperText={errors.old_password?.message}
                  fullWidth
                  size={"small"}
                  placeholder={"Old Password"}
                />
              </Grid>
              <Grid item md={6} sx={{ width: "100%" }}>
                <Box sx={{ fontWeight: "500", fontSize: "14px", mb: 1 }}>
                  New Password
                </Box>
                <TextField
                  type={showPassword1 ? "text" : "password"}
                  {...register3("new_password", {
                    required: "Please enter new password",
                  })}
                  error={Boolean(errors3.new_password)}
                  InputProps={{
                    style: { padding: "5px" },
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword1(!showPassword1)}
                      >
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                  helperText={errors.new_password?.message}
                  fullWidth
                  size={"small"}
                  placeholder={"New Password"}
                />
              </Grid>
              <Grid item md={6} sx={{ width: "100%" }}>
                <Box sx={{ fontWeight: "500", fontSize: "14px", mb: 1 }}>
                  Confirm Password
                </Box>
                <TextField
                  type={showPassword2 ? "text" : "password"}
                  {...register3("confirm_password", {
                    required: "Please enter Confirm password",
                    validate: (value) =>
                      value === newPassword ||
                      "New Password and Confirm Password must be the same",
                  })}
                  error={Boolean(errors3.confirm_password)}
                  InputProps={{
                    style: { padding: "5px" },
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword2(!showPassword2)}
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                  helperText={errors.confirm_password?.message}
                  fullWidth
                  size={"small"}
                  placeholder={"Confirm Password"}
                />
              </Grid>

              <Grid
                item
                md={12}
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    fontWeight: "500",
                    textAlign: "center",
                    borderRadius: "5px",
                    padding: "6px 30px",
                    cursor: "pointer",
                    fontSize: "14px",
                    mb: 1,
                    background: Colors.primary,
                    color: Colors.white,
                    "&:hover": {
                      background: Colors.primary,
                    },
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </SimpleDialog>
    </>
  );
}
