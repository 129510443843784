import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Grid,
  Paper,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import Colors from "../../../../assets/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";
import CaseServices from "../../../../api/CaseServices/case.index";
import { ErrorToaster, SuccessToaster } from "../../../../components/Toaster";
import { ErrorHandler } from "../../../../utils/ErrorHandler";
import SimpleDialog from "../../../../components/Dialog/index";
import { Images } from "../../../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function CaseDetailField({
  register,
  errors,
  label,
  name,
  watch,
  multiline,
  minRows,
  maxRows,
  isNumeric,
  localError,
  setLocalError,
  required,
  setCheckError,
  value,
}) {
  const newValues = watch(name);

  const validateInput = (newValues) => {
    if (isNumeric && value && /[^0-9]/.test(newValues)) {
      setCheckError(true);
      return "Only numbers are allowed";
    } else {
      setCheckError(false);
    }
    return "";
  };

  const handleChange = (e) => {
    const newValue = e.target.value;

    if (isNumeric) {
      setLocalError(validateInput(newValue));
    }

    register(name).onChange(e);
  };

  return (
    <Grid container alignItems="center">
      <Grid item md={6} xs={12}>
        <Typography variant="body2" sx={{ color: "black" }}>
          {label}:
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          multiline={multiline}
          minRows={minRows}
          value={value}
          maxRows={maxRows}
          {...register(name, {
            required: required ? "This field is required" : false,
          })}
          onChange={handleChange}
          inputProps={
            isNumeric ? { inputMode: "numeric", pattern: "[0-9]*" } : {}
          }
          error={Boolean(errors[name] || localError)}
          helperText={errors[name]?.message || localError}
          sx={{
            mt: 1,
            "& .MuiInputBase-root": {
              borderRadius: 1,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

function Dropdown({
  label,
  name,
  options,
  register,
  errors,
  required = false,
  watch,
  clearErrors,
  defaultValue = "",
}) {
  const value = watch(name);

  useEffect(() => {
    if (value && errors[name]) {
      clearErrors(name);
    }
  }, [value, errors, name, clearErrors]);

  return (
    <Grid container sx={{ mt: 1 }} alignItems="center">
      <Grid item md={6} xs={12}>
        <Typography variant="body2" sx={{ color: "black" }}>
          {label}:
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl
          fullWidth
          error={!!errors[name]}
          sx={{ backgroundColor: "transparent" }}
        >
          <Select
            {...register(name, {
              required: required ? `${label} is required` : false,
            })}
            size="small"
            defaultValue={defaultValue}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {errors[name] && (
            <FormHelperText>{errors[name]?.message}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
}
// const DateTimePickerComponent = ({
//   label,
//   name,
//   control,
//   errors,
//   clearErrors,
//   watch,
//   required = false,
//   defaultValue = null,

// }) => {
//   const values = watch(name);

//   useEffect(() => {
//     if (values && errors[name]) {
//       clearErrors(name);
//     }
//   }, [values, errors, name, clearErrors]);
//   return (
//     <Grid container alignItems="center">
//       <Grid item md={6} xs={12} sx={{ mt: 1 }}>
//         <Typography variant="body2" sx={{ color: Colors.black }}>
//           {label}:
//         </Typography>
//       </Grid>
//       <Grid item md={6} xs={12} sx={{ mt: 1 }}>
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <FormControl
//             fullWidth
//             error={!!errors[name]}
//             sx={{ backgroundColor: "transparent" }}
//             className="mui_date_picker"
//           >
//             <Controller
//               name={name}
//               control={control}
//               defaultValue={defaultValue}

//               render={({ field }) => (
//                 <DateTimePicker
//                   {...field}
//                   inputFormat="MM/DD/YYYY hh:mm A"
//                   onChange={(date) => field.onChange(date)}
//                   renderInput={(params) => (
//                     <TextField
//                       sx={{
//                         "& .MuiInputBase-input": {
//                           padding: "9.5px 14px !important",
//                         },
//                       }}
//                       {...params}
//                       size="small"
//                       fullWidth
//                       error={!!errors[name]}
//                       helperText={errors[name]?.message}
//                       InputProps={{}}
//                     />
//                   )}
//                 />
//               )}
//               rules={{
//                 required: required ? `${label} is required` : false,
//               }}
//             />
//             {errors[name] && (
//               <FormHelperText>{errors[name]?.message}</FormHelperText>
//             )}
//           </FormControl>
//         </LocalizationProvider>
//       </Grid>
//     </Grid>
//   );
// };
const DateTimePickerComponent = ({
  label,
  name,
  control,
  errors,
  clearErrors,
  watch,
  required = false,
  defaultValue = null,
}) => {
  // Initialize state with dayjs object
  const [selectedDate, setSelectedDate] = useState(defaultValue ? dayjs.utc(defaultValue) : null); 
  const value = watch(name);

  useEffect(() => {
    if (value && errors[name]) {
      clearErrors(name);
    }
  }, [value, errors, name, clearErrors]);

  return (
    <Grid container alignItems="center">
      <Grid item md={6} xs={12} sx={{ mt: 1 }}>
        <Typography variant="body2" sx={{ color: 'black' }}>
          {label}:
        </Typography>
      </Grid>
      <Grid item md={6} xs={12} sx={{ mt: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl
            fullWidth
            error={!!errors[name]}
            sx={{ backgroundColor: 'transparent' }}
            className="mui_date_picker"
          >
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  inputFormat="MM-DD-YYYY hh:mm A"
                  value={selectedDate}  
                  // onChange={(date) => {
                  //   setSelectedDate(date);  // Update state with dayjs object
                  //   field.onChange(date ? date.format('YYYY-MM-DD hh:mm') : null);
                  //   console.log( date.format('YYYY-MM-DD hh:mm'))  // Update form value with formatted date
                  // }}
                  onChange={(date) => {
                    // Update state with dayjs object in UTC
                    const utcDate = date ? dayjs.utc(date) : null;
                    setSelectedDate(utcDate);  
                    field.onChange(utcDate ? utcDate.format('YYYY-MM-DD hh:mm A') : null);  
                    console.log(utcDate ? utcDate.format('YYYY-MM-DD hh:mm A') : null); 
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        '& .MuiInputBase-input': {
                          padding: '9.5px 14px !important',
                        },
                      }}
                      {...params}
                      size="small"
                      fullWidth
                      error={!!errors[name]}
                      helperText={errors[name]?.message}
                      InputProps={{}}
                    />
                  )}
                />
              )}
              rules={{
                required: required ? `${label} is required` : false,
              }}
            />
            {errors[name] && (
              <FormHelperText>{errors[name]?.message}</FormHelperText>
            )}
          </FormControl>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};
function CaseDetailTabs({
  control,
  register,
  errors,
  handleSubmit,
  onNext,
  clearErrors,
  watch,
  isNumeric,
  localError,
  setLocalError,
  setCheckError,
  state,
  setValue,
}) {
  const [step, setStep] = useState(0);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mt: 3 }}>
        {step === 0 && (
          <>
            <MainInformation
              register={register}
              errors={errors}
              watch={watch}
              clearErrors={clearErrors}
              control={control}
              setCheckError={setCheckError}
              localError={localError}
              setLocalError={setLocalError}
              state={state}
              setValue={setValue}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            ></Box>
          </>
        )}
      </Box>
    </Box>
  );
}

function MainInformation({
  register,
  errors,
  watch,
  clearErrors,
  control,
  localError,
  setLocalError,
  setCheckError,
  state,
  setValue,
}) {
  const [initiatingPartyAdminAppealError, setInitiatingPartyAdminAppealError] =
    useState("");
  const [
    nonInitiatingPartyAdminAppealError,
    setNonInitiatingPartyAdminAppealError,
  ] = useState("");

  useEffect(() => {
    setValue("cmsResponsibleForNoticeOfOffer", state?.responsibleForNotice);
  }, []);
  return (
    <>
      <Paper sx={{ padding: 2, background: "transparent", boxShadow: "none" }}>
        <Grid container spacing={2} gap="10px">
          <Grid
            item
            xs={12}
            md={5.9}
            sx={{
              border: `1px solid ${Colors.textGrey}`,
              padding: 2,
              borderRadius: "10px",
            }}
          >
            <Typography variant="h6" sx={{}}>
              Initiating Party
            </Typography>
            <CaseDetailField
              register={register}
              errors={errors}
              label="Payer"
              name="initiatingPartyPayer"
              watch={watch}
              required={true}
            />
            <CaseDetailField
              register={register}
              errors={errors}
              label="Remit to"
              name="initiatingPartyRemitTo"
              watch={watch}
            />
            <Dropdown
              label="Type"
              name="initiatingPartyType"
              options={[
                { value: "internal", label: "Internal" },
                { value: "external", label: "External" },
              ]}
              register={register}
              errors={errors}
              clearErrors={clearErrors}
              required={false}
              watch={watch}
              setValue={setValue}
              defaultValue={state?.initiating?.type}
            />
            <CaseDetailField
              register={register}
              errors={errors}
              label="Contact"
              name="initiatingPartyContact"
              watch={watch}
            />
            <CaseDetailField
              register={register}
              errors={errors}
              label="Email"
              name="initiatingPartyEmail"
              watch={watch}
            />
            <CaseDetailField
              register={register}
              errors={errors}
              label="Administrative Appeal"
              name="initiatingPartyAdminAppeal"
              isNumeric={true}
              localError={initiatingPartyAdminAppealError}
              setLocalError={setInitiatingPartyAdminAppealError}
              watch={watch}
              setCheckError={setCheckError}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5.9}
            sx={{
              border: `1px solid ${Colors.textGrey}`,
              padding: 2,
              borderRadius: "10px",
            }}
          >
            <Typography variant="h6" sx={{}}>
              Non-Initiating Party
            </Typography>
            <CaseDetailField
              register={register}
              errors={errors}
              label="Payer"
              name="nonInitiatingPartyPayer"
              watch={watch}
              required={true}
            />
            <CaseDetailField
              register={register}
              errors={errors}
              label="Remit to"
              name="nonInitiatingPartyRemitTo"
              watch={watch}
            />

            <Dropdown
              label="Type"
              name="nonInitiatingPartyType"
              options={[
                { value: "internal", label: "Internal" },
                { value: "external", label: "External" },
              ]}
              register={register}
              errors={errors}
              required={false}
              clearErrors={clearErrors}
              watch={watch}
              setValue={setValue}
              defaultValue={state?.nonInitiating?.type}
            />
            <CaseDetailField
              register={register}
              errors={errors}
              label="Contact"
              name="nonInitiatingPartyContact"
              watch={watch}
            />
            <CaseDetailField
              register={register}
              errors={errors}
              label="Email"
              name="nonInitiatingPartyEmail"
              watch={watch}
            />
            <CaseDetailField
              register={register}
              errors={errors}
              label="Administrative Appeal"
              name="nonInitiatingPartyAdminAppeal"
              localError={nonInitiatingPartyAdminAppealError}
              setLocalError={setNonInitiatingPartyAdminAppealError}
              isNumeric={true}
              watch={watch}
              setCheckError={setCheckError}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          padding: 2,
          background: "transparent",
          boxShadow: "none",
          border: `1px solid ${Colors.textGrey}`,
          mr: 2.7,
          borderRadius: "10px",
        }}
      >
          <CaseDetailField
          register={register}
          errors={errors}
          label="Claimant Name"
          watch={watch}
          name="claimantName"
        />
        <CaseDetailField
          register={register}
          errors={errors}
          label="Insurance No"
          watch={watch}
          name="insurance"
        />
        <CaseDetailField
          register={register}
          errors={errors}
          label="Cost Outcome"
          watch={watch}
          name="costOutcome"
        />
        <DateTimePickerComponent
          label="Triaged On"
          name="triagedOn"
          control={control}
          errors={errors}
          required={false}
          watch={watch}
          clearErrors={clearErrors}
          setValue={setValue}
          defaultValue={state?.triagedOn ? dayjs(state.triagedOn) : null}
        />

        <Dropdown
          label="Triaged by"
          name="triagedBy"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.triagedBy}
        />
        <CaseDetailField
          register={register}
          errors={errors}
          label="Assignment Originator"
          watch={watch}
          name="assignmentOriginator"
        />
        <CaseDetailField
          register={register}
          errors={errors}
          label="Submitted by"
          watch={watch}
          name="submittedBy"
        />

        <DateTimePickerComponent
          label="Date Submitted (Easter)"
          name="dateSubmitted"
          control={control}
          errors={errors}
          required={false}
          watch={watch}
          clearErrors={clearErrors}
          setValue={setValue}
          defaultValue={state?.triagedOn ? dayjs(state.submittedDate) : null}
        />
        <Dropdown
          label="Review Co-Ordinator"
          name="reviewCoordinator"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.reviewCoOrdinator}
        />
        <Dropdown
          label="Review Co-Ordinator Assistant"
          name="reviewCoordinatorAssistant"
          options={[
            { value: "internal", label: "Internal" },
            { value: "external", label: "External" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.reviewCoOrdinatorAssistant}
        />
        <CaseDetailField
          register={register}
          errors={errors}
          label="Assigned For QA"
          watch={watch}
          name="assignedForQA"
        />

        <Dropdown
          label="Staff Status"
          name="staffStatus"
          options={[
            { value: "New", label: "New" },
            { value: "In-Progess", label: "In Progress" },
            { value: "On-Hold", label: "On Hold" },
            { value: "Closed", label: "Closed" },
            { value: "Completed", label: "Completed" },
          ]}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.staffStatus}
        />
        <Dropdown
          label="Client Status"
          name="clientStatus"
          options={[
            { value: "New", label: "New" },
            { value: "In-Progess", label: "In Progress" },
            { value: "On-Hold", label: "On Hold" },
            { value: "Closed", label: "Closed" },
            { value: "Completed", label: "Completed" },
          ]}
          register={register}
          errors={errors}
          required={false}
          watch={watch}
          clearErrors={clearErrors}
          setValue={setValue}
          defaultValue={state?.clientStatus}
        />

        <DateTimePickerComponent
          label="COI Date"
          name="coiDate"
          control={control}
          errors={errors}
          required={false}
          watch={watch}
          clearErrors={clearErrors}
          setValue={setValue}
          defaultValue={state?.triagedOn ? dayjs(state.coiDate) : null}
        />

        <Dropdown
          label="COI Originator"
          name="coiOriginator"
          options={[
            { value: "internal", label: "Internal" },
            { value: "external", label: "External" },
          ]}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.coiOriginator}
        />

        <Dropdown
          label="COI Result"
          name="coiResult"
          options={[
            { value: "internal", label: "Internal" },
            { value: "external", label: "External" },
          ]}
          register={register}
          errors={errors}
          required={false}
          clearErrors={clearErrors}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.coiResult}
        />
        <CaseDetailField
          register={register}
          errors={errors}
          label="COI Comment"
          watch={watch}
          name="coiComment"
        />

        <Dropdown
          label="Assigned Reviewer"
          name="assignedReviewer"
          options={[
            { value: "internal", label: "Internal" },
            { value: "external", label: "External" },
          ]}
          register={register}
          errors={errors}
          required={false}
          clearErrors={clearErrors}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.assingedReviewer}
        />
        <Dropdown
          label="Reviewer Status"
          name="reviewerStatus"
          options={[
            { value: "New", label: "New" },
            { value: "In-Progess", label: "In Progress" },
            { value: "On-Hold", label: "On Hold" },
            { value: "Closed", label: "Closed" },
            { value: "Completed", label: "Completed" },
          ]}
          register={register}
          errors={errors}
          required={false}
          clearErrors={clearErrors}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.reviewerStatus}
        />
      </Paper>
      <Paper
        sx={{
          padding: 2,
          mt: 2,
          background: "transparent",
          boxShadow: "none",
          border: `1px solid ${Colors.textGrey}`,
          mr: 2.7,
          borderRadius: "10px",
        }}
      >
        <Dropdown
          label="Type of Review"
          name="typeOfReview"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.typeOfReview}
        />
        <Dropdown
          label="Level of Review"
          name="levelOfReview"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.levelOfReview}
        />
        <Dropdown
          label="Case Reference Number"
          name="caseReferenceNumber"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.caseReferenceNumber}
        />
        <Dropdown
          label="Regulatory Board of Jurisdiction"
          name="regulatoryBoardOfJurisdiction"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.regulatoryBoardOfJurisdiction}
        />
        <Dropdown
          label="State where services rendered"
          name="stateWhereServicesRendered"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.stateWhereServiceRendered}
        />
        <Dropdown
          label="Health Plan Type"
          name="healthPlanType"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.healthPlanType}
        />
        <Dropdown
          label="Case Issue Category"
          name="caseIssueCategory"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.caseIssueCategory}
        />
        <Dropdown
          label="Type of Service"
          name="typeOfService"
          options={[
            { value: "external", label: "External" },
            { value: "internal", label: "Internal" },
          ]}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          watch={watch}
          setValue={setValue}
          defaultValue={state?.typeOfService}
        />

        <CaseDetailField
          register={register}
          errors={errors}
          label="Service Fee"
          name="serviceFee"
          watch={watch}
        />

        <DateTimePickerComponent
          label="Open Negotiations Started"
          name="openNegotiationsStarted"
          control={control}
          errors={errors}
          watch={watch}
          clearErrors={clearErrors}
          required={false}
          setValue={setValue}
          defaultValue={
            state?.triagedOn ? dayjs(state?.openNegotiationStarted) : null
          }
        />
        <DateTimePickerComponent
          label="Date Appeal Requested"
          name="dateAppealRequested"
          control={control}
          errors={errors}
          required={false}
          watch={watch}
          clearErrors={clearErrors}
          setValue={setValue}
          defaultValue={
            state?.triagedOn ? dayjs(state?.dateAppealRequested) : null
          }
        />
        <DateTimePickerComponent
          label="Report Due Date"
          name="reportDueDate"
          control={control}
          errors={errors}
          required={false}
          watch={watch}
          clearErrors={clearErrors}
          setValue={setValue}
          defaultValue={state?.triagedOn ? dayjs(state?.reportDueDate) : null}
        />

        <CaseDetailField
          multiline={true}
          minRows={2}
          maxRows={2}
          register={register}
          errors={errors}
          label="The Questions"
          name="questions"
          watch={watch}
        />

        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="body2" sx={{ color: Colors.black }}>
              In CMS Responsible for Notice of Offer:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="cmsResponsibleForNoticeOfOffer"
              control={control}
              rules={{ required: "This field is required" }}
              defaultValue={state?.responsibleForNotice}
              render={({ field }) => (
                <RadioGroup
                  row
                  {...field}
                  value={field.value || state?.responsibleForNotice}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              )}
            />
            {errors.cmsResponsibleForNoticeOfOffer && (
              <Typography variant="body2" color="error" sx={{ marginLeft: 2 }}>
                {errors.cmsResponsibleForNoticeOfOffer.message}
              </Typography>
            )}
          </Grid>
        </Grid>

        {/* Partially Eligible */}
        <Grid container sx={{}} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="body2" sx={{ color: Colors.black }}>
              Partially Eligible:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="partiallyEligible"
              defaultValue={state?.partiallyEligible}
              control={control}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <RadioGroup
                  row
                  {...field}
                  value={field.value || state?.partiallyEligible}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              )}
            />
            {errors.partiallyEligible && (
              <Typography variant="body2" color="error" sx={{ marginLeft: 2 }}>
                {errors.partiallyEligible.message}
              </Typography>
            )}
          </Grid>
        </Grid>

        {/* Pass Through */}
        <Grid container sx={{}} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="body2" sx={{ color: Colors.black }}>
              Pass Through:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="passThrough"
              control={control}
              // rules={{ required: "This field is required" }}
              render={({ field }) => (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox {...field} checked={field.value || false} />
                    }
                  />
                </FormGroup>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default function CaseDetails() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
    getValues,
    clearErrors,
    setValue,
  } = useForm();

  const [localError, setLocalError] = useState("");
  const [checkError, setCheckError] = useState(false);
  const [selectedCase, setSelectedCase] = useState([]);
  const [assignedDialog, setAssignedDialog] = useState(false);

  const { state } = useLocation();
  console.log(state);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    const obj = {
      initiating: {
        payer: data.initiatingPartyPayer,
        remit_to: data.initiatingPartyRemitTo,
        type: data.initiatingPartyType,
        contact: data.initiatingPartyContact,
        email: data.initiatingPartyEmail,
        administrativeAppeal: data.initiatingPartyAdminAppeal,
        preProcessResponseStatus: "Pending",
        responseStatus: "Pending",
        paymentStatus: "Paid",
      },
      nonInitiating: {
        payer: data.nonInitiatingPartyPayer,
        remit_to: data.nonInitiatingPartyRemitTo,
        type: data.nonInitiatingPartyType,
        contact: data.nonInitiatingPartyContact,
        email: data.nonInitiatingPartyEmail,
        administrativeAppeal: data.nonInitiatingPartyAdminAppeal,
        preProcessResponseStatus: "Pending",
        responseStatus: "Pending",
        paymentStatus: "Paid",
      },

      claimantName: data.claimantName,
      costOutcome: data.costOutcome,
      insurance: data.insurance,
      triagedOn: data.triagedOn
        ? data.triagedOn
        : null,
      triagedBy: data.triagedBy,
      assignmentOriginator: data.assignmentOriginator,
      submittedBy: data.submittedBy,
      submittedDate: data.dateSubmitted
        ? data.dateSubmitted
        : null,
      reviewCoOrdinator: data.reviewCoordinator,
      reviewCoOrdinatorAssistant: data.reviewCoordinatorAssistant,
      assignedForQA: data.assignedForQA,
      staffStatus: data.staffStatus,
      clientStatus: data.clientStatus,
      coiDate: data.coiDate
        ? data.coiDate
        : null,
      coiOriginator: data.coiOriginator,
      coiResult: data.coiResult,
      coiComment: data.coiComment,
      assingedReviewer: data.assignedReviewer,
      reviewerStatus: data.reviewerStatus,

      typeOfReview: data.typeOfReview,
      levelOfReview: data.levelOfReview,
      caseReferenceNumber: data.caseReferenceNumber,
      regulatoryBoardOfJurisdiction: data.regulatoryBoardOfJurisdiction,
      stateWhereServiceRendered: data.stateWhereServicesRendered,
      healthPlanType: data.healthPlanType,
      caseIssueCategory: data.caseIssueCategory,
      typeOfService: data.typeOfService,
      serviceFee: data.serviceFee,
      openNegotiationStarted: data.openNegotiationsStarted
        ? data.openNegotiationsStarted
        : null,
      dateAppealRequested: data.dateAppealRequested
        ? data.dateAppealRequested
        : null,
      reportDueDate: data.reportDueDate
        ? data.reportDueDate
        : null,
      questions: data.questions,
      responsibleForNotice:
        data.cmsResponsibleForNoticeOfOffer === "yes" ? true : false,
      partiallyEligible: data.partiallyEligible === "yes" ? true : false,
      passThrough: data.passThrough ? true : false,
    };
    console.log(obj);
    if (checkError) {
      ErrorToaster("Error on Submitting Fields");
    } else {
      try {
        const data = await CaseServices.updateCase(state?._id ,obj);
        SuccessToaster(data?.message);
        setSelectedCase(data?.data?.case);
        console.log(data?.data?.case?.caseID);
      } catch (error) {
        ErrorHandler(error);
        ErrorToaster(error);
      }
      setAssignedDialog(true);
    }
  };
  useEffect(() => {
    setValue("initiatingPartyPayer", state?.initiating?.payer);
    setValue("initiatingPartyRemitTo", state?.initiating?.remit_to);
    setValue("initiatingPartyType", state?.initiating?.type);
    setValue("initiatingPartyContact", state?.initiating?.contact);
    setValue("initiatingPartyEmail", state?.initiating?.email);
    setValue(
      "initiatingPartyAdminAppeal",
      state?.initiating?.administrativeAppeal
    );
    setValue(
      "initiatingPartyPreProcessResponseStatus",
      state?.initiating?.preProcessResponseStatus
    );
    setValue(
      "initiatingPartyResponseStatus",
      state?.initiating?.responseStatus
    );
    setValue("initiatingPartyPaymentStatus", state?.initiating?.paymentStatus);

    setValue("nonInitiatingPartyPayer", state?.nonInitiating?.payer);
    setValue("nonInitiatingPartyRemitTo", state?.nonInitiating?.remit_to);
    setValue("nonInitiatingPartyType", state?.nonInitiating?.type);
    setValue("nonInitiatingPartyContact", state?.nonInitiating?.contact);
    setValue("nonInitiatingPartyEmail", state?.nonInitiating?.email);
    setValue(
      "nonInitiatingPartyAdminAppeal",
      state?.nonInitiating?.administrativeAppeal
    );
    setValue(
      "nonInitiatingPartyPreProcessResponseStatus",
      state?.nonInitiating?.preProcessResponseStatus
    );
    setValue(
      "nonInitiatingPartyResponseStatus",
      state?.nonInitiating?.responseStatus
    );
    setValue(
      "nonInitiatingPartyPaymentStatus",
      state?.nonInitiating?.paymentStatus
    );

    setValue("costOutcome", state?.costOutcome);
    setValue("claimantName", state?.claimantName);
    setValue("insurance", state?.insurance);
    // setValue("triagedOn",state?.triagedOn ? moment(state?.triagedOn).format("YYYY-MM-DD"):null);
    setValue("triagedBy", state?.triagedBy);
    setValue("assignmentOriginator", state?.assignmentOriginator);
    setValue("submittedBy", state?.submittedBy);
    // setValue("submittedDate", state?.submittedDate);
    setValue("reviewCoordinator", state?.reviewCoOrdinator);
    setValue("reviewCoordinatorAssistant", state?.reviewCoOrdinatorAssistant);
    setValue("assignedForQA", state?.assignedForQA);
    setValue("staffStatus", state?.staffStatus);
    setValue("clientStatus", state?.clientStatus);
    // setValue("coiDate", state?.coiDate);
    setValue("coiOriginator", state?.coiOriginator);
    setValue("coiResult", state?.coiResult);
    setValue("coiComment", state?.coiComment);
    setValue("assignedReviewer", state?.assingedReviewer);
    setValue("reviewerStatus", state?.reviewerStatus);
    setValue("typeOfReview", state?.typeOfReview);
    setValue("levelOfReview", state?.levelOfReview);
    setValue("caseReferenceNumber", state?.caseReferenceNumber);
    setValue(
      "regulatoryBoardOfJurisdiction",
      state?.regulatoryBoardOfJurisdiction
    );
    setValue("stateWhereServiceRendered", state?.stateWhereServiceRendered);
    setValue("healthPlanType", state?.healthPlanType);
    setValue("caseIssueCategory", state?.caseIssueCategory);
    setValue("typeOfService", state?.typeOfService);
    setValue("serviceFee", state?.serviceFee);
    // setValue("openNegotiationsStarted", state?.openNegotiationStarted);
    // setValue("dateAppealRequested", state?.dateAppealRequested);
    // setValue("reportDueDate", state?.reportDueDate);
    setValue("questions", state?.questions);
    setValue(
      "cmsResponsibleForNoticeOfOffer",
      state?.responsibleForNotice == true ? "yes" : "no"
    );
    setValue(
      "partiallyEligible",
      state?.partiallyEligible == true ? "yes" : "no"
    );
    setValue("passThrough", state?.passThrough);
  }, []);
  return (
    <Box sx={{ mt: 10, mr: 0 }}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box sx={{ padding: "16px 12px 16px 24px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Update Case #{state?.caseID}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ color: Colors.textGrey, mb: 2 }}
                >
                  Enter the following details to update a new case
                </Typography>
              </Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  borderRadius: "30px",
                  height: "20px",
                  padding: "19px 40px",
                  mr: 3,
                  textTransform: "capitalize",
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CaseDetailTabs
                register={register}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit(onSubmit)}
                watch={watch}
                clearErrors={clearErrors}
                localError={localError}
                setLocalError={setLocalError}
                setCheckError={setCheckError}
                state={state}
                setValue={setValue}
              />
            </form>
            {assignedDialog && (
              <>
                <SimpleDialog
                  open={assignedDialog}
                  onClose={() => setAssignedDialog(false)}
                >
                  <Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img src={Images.question} />
                    </Box>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "17px",
                      }}
                    >
                      Case Updated
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#666666",
                        fontSize: "14px",
                        marginTop: "8px",
                      }}
                    >
                      Case Updated "{state?.caseID}"
                    </p>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: Colors.white,
                        background: Colors.black,
                        margin: "10px auto",
                        width: "150px",
                        py: 1.2,
                        cursor: "pointer ",
                        borderRadius: "10px",
                      }}
                      onClick={() => navigate(`/tickets/details/${state?._id}`,{state:selectedCase})}
                    >
                      View Case
                    </Box>
                  </Box>
                </SimpleDialog>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
