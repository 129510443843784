  import React, { useState } from "react";
  import {
    Box,
    Typography,
    Tabs,
    Tab,
    TextField,
    Grid,
    Paper,
    Button,
    Container,
    LinearProgress,
    IconButton,
    FormControlLabel,
    Radio,
    Checkbox,
    FormGroup,
    RadioGroup,
    Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  } from "@mui/material";
  import { Add, Visibility, Edit, Delete } from '@mui/icons-material';
  import CloseIcon from "@mui/icons-material/Close";
  import Colors from "../../../../assets/styles";
  import { Svgs } from "../../../../assets/images";
import { useLocation } from "react-router-dom";
import moment from "moment";
  function CaseDetailTabs({state}) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            borderBottom: `1px solid ${Colors.textGrey}`,
            width: { md: "60%", xs: "100%" },
          }}
        >
          <Tab
            label="Internal"
            sx={{
              fontWeight: value === 0 ? "bold" : "normal",
            }}
          />
          <Tab
            label="IDR Case"
            sx={{
              fontWeight: value === 1 ? "bold" : "normal",
            }}
          />
          <Tab
            label="Document Files"
            sx={{
              fontWeight: value === 2 ? "bold" : "normal",
            }}
          />
        </Tabs>
        <Box sx={{ mt: 3 }}>
          {value === 0 && <MainInformation state={state} />}
          {value === 1 && <GeneralInformation state={state}/>}
          {value === 2 && <DocumentFiles state={state} />}
        </Box>
      </Box>
    );
  }
  function MainInformation({state}) {
    return (
      <>
        <Paper sx={{ padding: 2, background: "transparent", boxShadow: "none" }}>
          <Grid container spacing={2} gap="9px">
            <Grid
              item
              xs={12}
              md={5.9}
              sx={{
                border: `1px solid ${Colors.textGrey}`,
                padding: 2,
                borderRadius: "10px",
              }}
            >
              <CaseDetailField
                label="Internal Id"
                value="Prime Health Care LLC"
              />
              <CaseDetailField label="Case Reference Number" value="-" />
              <CaseDetailField label="State" value="Provider" />
              <CaseDetailField
                label="Priority"
                value="Prime Health Care Shasta LLC IDR Group"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5.9}
              sx={{
                border: `1px solid ${Colors.textGrey}`,
                padding: 2,
                borderRadius: "10px",
              }}
            >
              <CaseDetailField label="Caliment Name" value="Health Comp" />
              <CaseDetailField label="Date Submitted" value="-" />
              <CaseDetailField label="Assignment Group" value="Plan/Issuer" />
              <CaseDetailField
                label="Assignment To"
                value="HealthComp IDR Group"
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            padding: 2,
            background: "transparent",
            boxShadow: "none",
            border: `1px solid ${Colors.textGrey}`,
            mr: 3,
            borderRadius: "10px",
          }}
        >
          <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
            Case Activities
          </Typography>
          <Grid container>
            <Grid item md={3} xs={12}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
                  Work Notes:
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9} xs={12}>
              <Box
                sx={{
                  background: "#e9eaeb",
                  borderColor: "divider",
                  borderRadius: "10px",
                  p:2,
                  border:"1px solid black"  
                }}
              >
              
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                eu congue nibh. Fusce ex arcu, consectetur sed nisl non, lacinia
                sodales purus. Duis vitae aliquam mi. Nunc iaculis commodo orci
                nec feugiat. Pellentesque blandit ipsum sit amet ex elementum,
                pharetra condimentum tortor dictum. Nulla lobortis, tellus tempus
                congue laoreet, elit erat lobortis velit, ut efficitur est arcu eu
                nunc. Donec rutrum lacus neque, a finibus tortor vehicula nec
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  my: 2,
                }}
              >
                <FormControlLabel
                  control={<Checkbox id="additionalNotes" />}
                  label="Additional Notes (Customer Visible)"
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    span: {
                      fontSize: "13px",
                      fontWeight: "bold",
                    },
                  }}
                />
                <Box sx={{ textAlign: "right" }}>
                  <Button variant="contained" color="primary">
                    Post
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item md={3} xs={12}>
              <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
                Activities:
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Box
                sx={{ p: 2, mb: 2, background: "#e9eaeb", borderRadius: "10px",border:"1px solid black" }}
              >
                <Box
                  sx={{ display: "grid", gridTemplateColumns: "1fr auto", mb: 1 }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    System
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", color: "text.secondary" }}
                  >
                    Email Sent - 2024-08-21 - 12:24 AM
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <i className="fas fa-envelope" style={{ marginRight: 8 }}></i>
                  <strong>Email Sent</strong>
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Subject:</strong> Incident INC0141891 - Comments Added
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>From:</strong> Service Desk (Replies Will not be Read)
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>To:</strong> info@polastar.com
                </Typography>
                <Button variant="text" color="primary">
                  Show Details
                </Button>
              </Box>

              {/* Repeat the Paper component for other activities */}
              <Box
                sx={{ p: 2, mb: 2, background: "#e9eaeb", borderRadius: "10px",border:"1px solid black" ,borderLeft:"5px solid black" }}
              >
                <Box
                  sx={{ display: "grid", gridTemplateColumns: "1fr auto", mb: 1 }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Johnathan Hasan
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", color: "text.secondary" }}
                  >
                    Additional Comments
                  </Typography>
                </Box>

                <Typography variant="body2" sx={{ p: 1 }}>
                  <strong>
                    Hello! is this being considered yet? I’m worried about my
                    cars.
                  </strong>
                </Typography>
              </Box>

              <Box
                sx={{ p: 2, mb: 2, background: "#e9eaeb", borderRadius: "10px",border:"1px solid black" }}
              >
                <Box
                  sx={{ display: "grid", gridTemplateColumns: "1fr auto", mb: 1 }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    System
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", color: "text.secondary" }}
                  >
                    Email Sent - 2024-08-21 - 12:24 AM
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <i className="fas fa-envelope" style={{ marginRight: 8 }}></i>
                  <strong>Email Sent</strong>
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Subject:</strong> Incident INC0141891 - Comments Added
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>From:</strong> Service Desk (Replies Will not be Read)
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>To:</strong> info@polastar.com
                </Typography>
                <Button variant="text" color="primary">
                  Show Details
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }

  function CaseDetailField({ label, value }) {
    return (
      <Grid container sx={{ mb: 2 }} alignItems="center">
        <Grid item md={6} xs={12}>
          <Typography variant="body2" sx={{ color: Colors.black }}>
            {label}:
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            value={value}
            InputProps={{
              readOnly: true,
              style: {
                fontWeight: "bold", // Make text bold
              },
            }}
            sx={{
              mt: 1,
              "& .MuiInputBase-root": {
                backgroundColor: Colors.lightGrey, // Match background color
                borderRadius: 1, // Slight rounding
              },
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function GeneralInformation({state}) {
    return (
      <>
        <Paper sx={{ padding: 2, background: "transparent", boxShadow: "none" }}>
          <Grid container spacing={2} gap="9px">
            <Grid
              item
              xs={12}
              md={5.9}
              sx={{
                border: `1px solid ${Colors.textGrey}`,
                padding: 2,
                borderRadius: "10px",
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Initiating Party
              </Typography>
              <CaseDetailField label="Payer"  value={state?.initiating?.payer?state?.initiating?.payer:"-"} />
              <CaseDetailField label="Remit to"  value={state?.initiating?.remit_to ?state?.initiating?.remit_to:"-"} />
              <CaseDetailField label="Type"value={state?.initiating?.type ?state?.initiating?.type:"-"} />
              <CaseDetailField
                label="Contact"
                value={state?.initiating?.contact ?state?.initiating?.contact:"-"}
              />
              <CaseDetailField
                label="Email"
                value={state?.initiating?.email ?state?.initiating?.email:"-"}
              />
              <CaseDetailField label="Administrative Appeal" value={state?.initiating?.administrativeAppeal ?"$" + state?.initiating?.administrativeAppeal:"$0"} />
              <CaseDetailField
                label="Pre-Process Response Status"
                value={state?.initiating?.preProcessResponseStatus ?state?.initiating?.preProcessResponseStatus:"-"}
              />
              <CaseDetailField
                label="Response Status"
                value={state?.initiating?.responseStatus ?state?.initiating?.responseStatus:"-"}
              />
              <CaseDetailField
                label="Payment Status"
                value={state?.initiating?.paymentStatus ?state?.initiating?.paymentStatus:"-"}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5.9}
              sx={{
                border: `1px solid ${Colors.textGrey}`,
                padding: 2,
                borderRadius: "10px",
              }}
            >
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Non-Initiating Party
              </Typography>
              <CaseDetailField label="Payer" value={state?.nonInitiating?.payer ?state?.nonInitiating?.payer:"-"} />
              <CaseDetailField label="Remit to" value={state?.initiating?.remit_to ?state?.initiating?.remit_to :"-"} />
              <CaseDetailField label="Type" value={state?.nonInitiating?.type ?state?.nonInitiating?.type:"-"}/>
              <CaseDetailField label="Contact" value={state?.nonInitiating?.contact ?state?.nonInitiating?.contact:"-"}/>
              <CaseDetailField label="Email" value={state?.nonInitiating?.email ?state?.nonInitiating?.email:"-"}/>
              <CaseDetailField label="Administrative Appeal" value={state?.nonInitiating?.administrativeAppeal ?"$" + state?.nonInitiating?.administrativeAppeal :"$0"}/>
              <CaseDetailField
                label="Pre-Process Response Status"
                value={state?.nonInitiating?.preProcessResponseStatus ?state?.nonInitiating?.preProcessResponseStatus:"-"}
              />
              <CaseDetailField
                label="Response Status"
                value={state?.nonInitiating?.responseStatus ?state?.nonInitiating?.responseStatus:"-"}
              />
              <CaseDetailField
                label="Payment Status"
                value={state?.nonInitiating?.paymentStatus ?state?.nonInitiating?.paymentStatus:"-"}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            padding: 2,
            background: "transparent",
            boxShadow: "none",
            border: `1px solid ${Colors.textGrey}`,
            mr: 2.4,
            borderRadius: "10px",
          }}
        >
          <CaseDetailField
            label="Cost Outcome"
            value={state?.costOutcome ?"$"+state?.costOutcome:"$0 "}
          />
          <CaseDetailField label="Triaged On" value={state?.triagedOn ?moment.utc(state?.triagedOn).format("MM-DD-YYYY hh:mm A") :"-"}/>
          <CaseDetailField label="Triaged by" value={state?.triagedBy ?state?.triagedBy :"-"}/>
          <CaseDetailField
            label="Assignment Originator"
            value={state?.assignmentOriginator ?state?.assignmentOriginator :"-"}/>
          <CaseDetailField label="Submitted by" value={state?.submittedBy ?state?.submittedBy :"-"}/>
          <CaseDetailField label="Date Submitted (Easter)" value={state?.submittedDate ?moment.utc(state?.submittedDate).format("MM-DD-YYYY hh:mm A ") :"-"}/>
          <CaseDetailField label="Review Co-Ordinator" value={state?.reviewCoOrdinator ?state?.reviewCoOrdinator :"-"}/>
          <CaseDetailField
            label="Review Co-Ordinator Assistant"
            value={state?.reviewCoOrdinatorAssistant ?state?.reviewCoOrdinatorAssistant :'-'}/>
          <CaseDetailField label="Assigned For QA" value={state?.assignedForQA ? state?.assignedForQA :"-" }/>
          <CaseDetailField label="Staff Status" value={state?.staffStatus ?state?.staffStatus:"-"}/>
          <CaseDetailField
            label="Client Status"
            value={state?.clientStatus ?state?.clientStatus :"-"}/>
          <CaseDetailField
            label="COI Date" value={state?.coiDate ?moment.utc(state?.coiDate).format("MM-DD-YYYY hh:mm A") :'-'}/>
          <CaseDetailField
            label="COI Originator" value={state?.coiOriginator ?state?.coiOriginator : "-"}/>
          <CaseDetailField
            label="COI Comment"value={state?.coiComment ? state?.coiComment:"-"}/>
          <CaseDetailField
            label="Assigned Reviewer"
            value={state?.assingedReviewr ?state?.assingedReviewer :"-"}/>
          <CaseDetailField
            label="Reviewer Status"
            value={state?.reviewerStatus ?state?.reviewerStatus :'-'}/>
        </Paper>
        <Paper
          sx={{
            padding: 2,
            background: "transparent",
            boxShadow: "none",
            border: `1px solid ${Colors.textGrey}`,
            mr: 2.4,
            mt: 2,
            borderRadius: "10px",
          }}
        >
          <CaseDetailField label="Type of Review"  value={state?.typeOfReview ?state?.typeOfReview :'-'}/>
          <CaseDetailField
            label="Level of Review"
             value={state?.levelOfReview ? state?.levelOfReview :"-"}/>
         
          <CaseDetailField label="Case Reference Number"  value={state?.caseReferenceNumber ? state?.caseReferenceNumber:"-"}/>
          <CaseDetailField
            label="Regulatory Board of Jurisdiction"
             value={state?.regulatoryBoardOfJurisdiction ?state?.regulatoryBoardOfJurisdiction :'-'}/>
         
          <CaseDetailField
            label="State where services rendered"
             value={state?.stateWhereServiceRendered ?state?.stateWhereServiceRendered :"-"}/>
         
          <CaseDetailField label="Health Plan Type"  value={state?.healthPlanType ?state?.healthPlanType :"-"}/>
          <CaseDetailField
            label="Case Issue Category"
             value={state?.caseIssueCategory ?state?.caseIssueCategory:"-"}/>
         
          <CaseDetailField
            label="Type of Service"
             value={state?.typeOfService ?state?.typeOfService :"-"}/>
         
          <CaseDetailField label="Service Fee" value={state?.serviceFee?"$" + state?.serviceFee :"$0"}/>
          <CaseDetailField label="Open Negotiations Started" value={state?.openNegotiationStarted ?moment.utc(state?.openNegotiationStarted).format("MM-DD-YYYY hh:mm A") :'-'}/>
          <CaseDetailField label="Date Appeal Requested" value={state?.dateAppealRequested ?moment.utc(state?.dateAppealRequested).format("MM-DD-YYYY hh:mm A"):"-"}/>
          <CaseDetailField label="Report Due Date" value={state?.reportDueDate ? moment.utc(state?.reportDueDate).format("MM-DD-YYYY hh:mm A") :"-"}/>
          <Grid container sx={{ mb: 2 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ color: Colors.black }}>
                The Questions
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                value={state?.questions? state?.questions:"-"}
                multiline
                minRows={2}
                maxRows={2}
                sx={{fontWeight:"bold"}}
                InputProps={{
                  style: {
                    backgroundColor: Colors.lightGrey,
                    fontWeight:"bold",
                    borderRadius: "4px",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 2 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ color: Colors.black }}>
                In CMS Responsible for Notice of Offer:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <RadioGroup row  value={state?.responsibleForNotice}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 2 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ color: Colors.black }}>
                Partially Eligible:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <RadioGroup row value={state?.partiallyEligible}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 2 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ color: Colors.black }}>
                Pass Through:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup row>
                <FormControlLabel checked={state?.passThrough} control={<Checkbox />} label="" />
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }

  function DocumentFiles() {
    const data = Array(8).fill({
      name: 'Proof of open negotiation.pdf',
      fileName: 'Proof of open negotiation.pdf',
      disputeLineItem: '1-281051102-1',
      uploadedOn: '3/11/2024 9:30 AM',
      uploadedBy: '3/11/2024 9:30 AM'
    });
    return (
      <Box sx={{mr:2,borderRadius:'10px'}}>
       <TableContainer sx ={{border:`1px solid ${Colors.textGrey}`,borderRadius:'10px'}}>
      <Table>
        <TableHead>
          <TableRow>
          
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>Name</TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>File Name</TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>Dispute Line Item</TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>Uploaded On</TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>Uploaded By</TableCell>
            {/* <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>Client NIP</TableCell> */}
            {/* <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>Client IP</TableCell> */}
            {/* <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>Reviewer</TableCell> */}
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
              <IconButton color="primary">
                <Add />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow sx={{background:Colors.white}}> 
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}></TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
              <TextField size="small" variant="outlined" />
            </TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
              <TextField size="small" variant="outlined" />
            </TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
              <TextField size="small" variant="outlined" />
            </TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
              <TextField size="small" variant="outlined" />
            </TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
              <TextField size="small" variant="outlined" />
            </TableCell>
            {/* <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
              <TextField size="small" variant="outlined" />
            </TableCell>
            <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
              <TextField size="small" variant="outlined" />
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
             
              <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>{row.name}</TableCell>
              <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>{row.fileName}</TableCell>
              <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>{row.disputeLineItem}</TableCell>
              <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>{row.uploadedOn}</TableCell>
              <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>{row.uploadedBy}</TableCell>
              {/* <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
                <Checkbox checked={row.clientNIP} />
              </TableCell>
              <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
                <Checkbox checked={row.clientIP} />
              </TableCell>
              <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
                <Checkbox checked={row.reviewer} />
              </TableCell> */}
              
              <TableCell sx={{border:`1px solid ${Colors.textGrey}`}}>
                <Box sx={{display:"flex"}}>

                <IconButton>
                  <Visibility />
                </IconButton>
                <IconButton>
                  <Edit />
                </IconButton>
                {/* <IconButton>
                  <Delete />
                </IconButton> */}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </Box>
    );
  }

  function VerifyCaseSidebar() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [error, setError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [closeCase, setCloseCase] = useState(false);

    const questions = [
      "What is your policy number?",
      "What type of insurance claim are you submitting?",
      "What information is essential to include when describing an incident for an insurance claim?",
      "How should you submit repair estimates or quotes for your insurance claim?",
      "What is the purpose of the excess in an insurance claim?",
    ];
    const [answers, setAnswers] = useState(Array(questions.length).fill(""));

    const handleNext = () => {
      if (answers[currentQuestion].trim() === "") {
        setError("This field cannot be empty.");
        return;
      }
      setError("");
      setCurrentQuestion((prev) => prev + 1);
    };

    const handlePrevious = () => {
      setError("");
      setCurrentQuestion((prev) => prev - 1);
    };

    const handleChange = (e) => {
      const newAnswers = [...answers];
      newAnswers[currentQuestion] = e.target.value;
      setAnswers(newAnswers);
    };

    const handleSubmit = () => {
      if (answers[currentQuestion].trim() === "") {
        setError("This field cannot be empty.");
        return;
      }
      setSubmitted(true);
    };
    const handleVerifyCase = () => {
      setCloseCase(true);
    };

    return (
      <>
        {closeCase ? (
          <>
            <Paper
              sx={{
                padding: "16px 16px 16px 16px",
                background: "transparent",
                boxShadow: "none",
                border: `1px solid grey`,
                // height: "100vh",
                width: "86%",
                  position:"sticky",
                  top:"130px"
              }}
              // onClick={handleIconClick}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "15px " }}>
                <Box
                  onClick={() => setCloseCase(false)}
                  sx={{ cursor: "pointer" }}
                  dangerouslySetInnerHTML={{ __html: Svgs["resize"] }}
                />
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Verify the Case
                  </Typography>
                  <Typography variant="caption" sx={{ color: Colors.textGrey }}>
                    CASE ID: #27
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </>
        ) : (
          <>
            {submitted ? (
              <>
                <Paper
                  sx={{
                    padding: "16px",
                    background: "transparent",
                    boxShadow: "none",
                    border: `1px solid grey`,
                    // height: "100vh",
                    width: "86%",
                      position:"sticky",
                top:"130px"
                  }}
                >
                  <Box sx={{ mb: 3 }}>
                    <IconButton
                      onClick={handleVerifyCase}
                      sx={{
                        mb: 3,
                        background: Colors.textGrey,

                        ":hover": {
                          background: Colors.textGrey,
                        },
                      }}
                    >
                      <CloseIcon sx={{ color: Colors.white, fontSize: "14px" }} />
                    </IconButton>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                      Your Answers
                    </Typography>
                    <Typography variant="subtitle2" sx={{ mb: 2, color: "grey" }}>
                      CASE ID: #27
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 3, color: "grey" }}>
                      These are the answers which were given by you earlier:
                    </Typography>
                  </Box>

                  {questions.map((question, index) => (
                    <Box sx={{ mb: 2 }} key={index}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Q{index + 1}. {question}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "grey" }}>
                        A{index + 1}. {answers[index]}
                      </Typography>
                    </Box>
                  ))}

                  <Button
                    variant="contained"
                    sx={{
                      m: "16px auto",
                      display: "flex ",
                      justifyContent: "center",
                      background: Colors.black,
                      borderRadius: "30px",
                      ":hover": {
                        background: Colors.black,
                      },
                    }}
                  >
                    Confirm
                  </Button>
                </Paper>
              </>
            ) : (
              <>
                <Paper
                  sx={{
                    padding: "16px 16px 16px 16px",
                    background: "transparent",
                    boxShadow: "none",
                    border: `1px solid grey`,
                    // height: "100vh",
                    width: "86%",
                      position:"sticky",
                top:"130px"
                  }}
                >
                  <Box sx={{ mb: 3 }}>
                    <IconButton
                      onClick={handleVerifyCase}
                      sx={{
                        mb: 3,
                        background: Colors.textGrey,

                        ":hover": {
                          background: Colors.textGrey,
                        },
                      }}
                    >
                      <CloseIcon sx={{ color: Colors.white, fontSize: "14px" }} />
                    </IconButton>

                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: "27px",
                      }}
                    >
                      Verify the Case
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 2, color: "grey", textAlign: "center" }}
                    >
                      CASE ID: #27
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ my: 3, color: "grey", textAlign: "center" }}
                    >
                      Answer the following questions in order to proceed!
                    </Typography>
                  </Box>

                  <LinearProgress
                    variant="determinate"
                    value={(currentQuestion + 1) * 20}
                    sx={{
                      mb: 3,
                      width: "60%",
                      margin: "10px auto",
                      height: "6px",
                    }}
                  />

                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", my: 4, textAlign: "center" }}
                    >
                      Question {currentQuestion + 1}
                    </Typography>
                    <Typography variant="body1" sx={{ color: Colors.black }}>
                      {questions[currentQuestion]}
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Your Answer"
                      value={answers[currentQuestion]}
                      onChange={handleChange}
                      error={Boolean(error)}
                      helperText={error}
                      sx={{ mt: 2, mb: 3 }}
                    />
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 auto",
                        color: Colors.textGrey,
                        fontSize: "14px",
                      }}
                    >
                      Skip
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 3,
                        mb: 10,
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          background: Colors.textGrey,
                          borderRadius: "30px",
                          ":hover": {
                            background: Colors.textGrey,
                          },
                        }}
                        disabled={currentQuestion === 0}
                        onClick={handlePrevious}
                      >
                        Previous
                      </Button>
                      {currentQuestion < questions.length - 1 ? (
                        <Button
                          variant="contained"
                          sx={{
                            background: Colors.black,
                            borderRadius: "30px",
                            ":hover": {
                              background: Colors.black,
                            },
                          }}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{
                            background: Colors.black,
                            borderRadius: "30px",
                            ":hover": {
                              background: Colors.black,
                            },
                          }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Paper>
              </>
            )}
          </>
        )}
      </>
    );
  }

  export default function CaseDetails() {
    const {state} = useLocation()
    console.log(state)
    return (
      <Box sx={{ mt: 10, mr: 0 }}>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Box sx={{ padding: "16px 0px 16px 16px" }}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                {state?.initiating?.payer ? state?.initiating?.payer:""}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ mb: 4, color: Colors.textGrey }}
              >
                CASE ID: #{state?.caseID}
              </Typography>
              <CaseDetailTabs state={state} />
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ pt: 2, pr: 1 }}>
           
              
            <VerifyCaseSidebar />
      
          </Grid>
        </Grid>
      </Box>
    );
  }
